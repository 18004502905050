import * as React from 'react'

import WpApi from './wpapi'
import './css/HdaList.css'

import './css/RmGlobals.css'
import RmGlobalField from './RmGlobalField'

interface MyProps {

}
type MyState = {
    sections:any
    tech:any
    
    msg:string
}

class RmGlobals extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    msg: '',
	    sections: {},
	    tech:{}
	}
    }
    init(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	let d = {
	    view: 'rm_globals'
	}
	let path = '/calc'

	this.fetchPromise = WpApi.do_get(path, d,(r:any) => {
	    if(r.code === "rest_forbidden"){
		this.setState({msg: 'No access. '+r.message})
	    }else{
		this.setState({
		    sections: r.sections,
		    tech: r.tech_settings
		})
	    }
	})
    }
    componentDidMount(){
	console.log('did mount')
	this.init()
    }
    render_fields(fields:Array<any>){
	return (
	    <div className="steps">
		{fields.map((e:any) => <RmGlobalField tup={e} />)}
	    </div>
	)
    }
    render_sect(tup:any){
	return (
	    <div className="section globals cluster l1-cluster">
		<div className="header">
		    <div className="label">{tup.label}</div>
		</div>
		<div className="body">
		    {this.render_fields(tup.steps)}
		</div>
	    </div>
	)
    }
    render_tech_opt(slug:string,tup:any,i:number){
	return (
	    <div>{slug.toUpperCase()} : {tup.label}</div>
	)
    }
    
    render_tech_hdr(){
	let data = this.state.tech
	return (
	    <div>
		{Object.entries(data.options).map(([slug,tup]:[string,any],i:number) => this.render_tech_opt(slug,tup,i))}
	    	<div className="tech-tup">
		<div className="values">
		<div>-</div>
		{Object.entries(data.options).map(([slug,tup]:[string,any],i:number) => {
		    return (<div>{slug.toUpperCase()}</div>)
		})}
		</div>
		</div>
	    </div>
	)
    }
    render_techtup(slug:string,tup:any,i:number){
	return (
	    <div className="tech-tup">
		<div className="values">
		<div>{tup.label}</div>
		    {Object.entries(tup.values).map(([fslug,row]:[string,any]) => {
			return (<div title={fslug.toUpperCase()}>{row}</div>)
		    })}
		</div>
	    </div>
	)
    }

    render_techsettings(){
	let data = this.state.tech
	if(data === undefined || data.fields === undefined ){return null}
	return (
	    <div className="tech-settings">
		<h3>Tech-settings</h3>
		{this.render_tech_hdr()}
		{Object.entries(data.fields).map(([slug,tup]:[string,any],i:number) => this.render_techtup(slug,tup,i))}
	    </div>
	)

    }
    render_sections(){
	let data = this.state.sections
	if(data.basis === undefined){return null}
	return (
	    <div>
	    	<h3>RM-basis</h3>
		<div className="sections-list">
		    {data.basis.map((e:any) => this.render_sect(e))}
		</div>
	    	<h3>RM-technieken</h3>
		<div className="sections-list">
		    {data.techniek.map((e:any) => this.render_sect(e))}
		</div>
		
	    </div>
	)
	
    }
				      
    render(){
	return (
	    <div className="hd-actions-react">
		<h3>Algemene rekenvariabelen, HD-onafhankelijk</h3>
		{this.render_techsettings()}
		<div className="rm-globals sections">
		    {this.render_sections()}
		</div>



		
	    </div>
	)
    }
}

export default RmGlobals
