import * as React from 'react'

import WpApi from './wpapi'
import './css/HdaList.css'


interface MyProps {
    tup:any,
    config:{ [k: string]: string }
    post_id:number,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    mode:string
    inputvalue:string
    value:string
    is_loading:boolean
}

class RmInvoerField extends React.PureComponent<MyProps,MyState> {
	
    constructor(props: MyProps) {
        super(props)

	this.click_value = this.click_value.bind(this)
	this.click_show_votes = this.click_show_votes.bind(this)
	this.change_input_val = this.change_input_val.bind(this)
	this.input_submit = this.input_submit.bind(this)
	this.change_select = this.change_select.bind(this)
	this.on_key_up = this.on_key_up.bind(this)
	
	this.state = {
	    mode:'read',
	    value:'',
	    is_loading:false,
	    inputvalue:''
	}
    }
    componentDidMount(){
	if(this.props.tup.winner !== false){
	    //this.setState({inputvalue: this.props.tup.winner})
	}
    }
    click_value(e:any){
	if(this.state.mode === 'read'){
	    this.setState({mode:'write'})
	}
    }
    render_edit_widget(){
	let tup = this.props.tup
	let value = tup.value
	let append = tup.append
	if(tup.cft_identifier.includes('num-input')){
	    return (
		<div className="input-with-btn">
		    <input value={this.state.inputvalue} onKeyUp={this.on_key_up}
		onChange={this.change_input_val} />
		    <span> {append} </span>
		    <img src={require("./img/save-icon-floppy.jpg")} onClick={this.input_submit} />
		 </div>
	    )
	}else if(tup.cft_identifier.includes('option-input')){
	    let choices = { 'kies': '- kies -', ...tup.choices}
	    let options = Object.entries(choices).map(([k,lab]:[string,any]) => (
		<option key={k} value={k}>{lab}</option>
	    ))
	    return (
		<select value={this.state.inputvalue}
		onChange={this.change_select}
		    >
		    {options}
		</select>
	    )
	}else{
	    return (<div> ? </div>)
	}
    }
    str_val(v:string){
	let tup = this.props.tup
	let cfid = tup.cft_identifier
	
	if(cfid.includes('option-input')){
	    return tup.choices[v]
	}
	return v
    }
    get_user_vote(){
	let tup = this.props.tup	
	let append = tup.append
	if(tup.uservote === undefined){
	    return null
	}
	let val = tup.uservote.value
	if(this.state.inputvalue !== ""){
	    val = this.state.inputvalue 
	}

	if(this.props.config.dashboard_mode === "adviseur"){
	    val = tup.winner
	}
	let strval = this.str_val(val)
	return this.state.mode === "read" ? (
	    <div className="value editable" onClick={this.click_value}>
		<span> {strval} </span> {append} 
		<img src={require("./img/edit-svg-icon-4.jpg")} />
		</div>
	) : (
	    <div className="edit-widget">
		{this.render_edit_widget()}
	    </div>
	)
	

    }
    render_winner_value(){
	let tup = this.props.tup
	let value = tup.value

	let append = tup.append
	if(tup.cft_identifier === 'txt-input-once'){
	    return (
	    <div className="value" onClick={this.click_value}>
		    {value}
		</div>
	    )
	}
	let cfid = tup.cft_identifier
	let v = tup.winner
	if(v === null || v === "" || v === undefined){return null}
	let strval = this.str_val( v)
	if(strval === "" || strval === null){return null}
	return (
	    <div className="default winner">Advies waarde: {strval}</div>
	)
    }
    on_key_up(e:any){
	if(e.keyCode === 13){
	    // Enter key was pressed
	    this.input_submit(null)
	}
    }
    change_select(e:any){
	let v = e.target.value
	console.log(v)
	this.setState({
	    inputvalue : v,
	    mode:'read'
	}, () => {
	    this.post_vote('choice')	 
	})
    }
    change_input_val(e:any){
	let v = e.target.value
	if(this.props.tup.cft_identifier.includes('num-input')){
	    if(isNaN(Number(v))){
		return
	    }
	}
	console.log(v)
	this.setState({inputvalue : v})
    }
    // submit changed input (e.g. a number)
    input_submit(e:any){
	console.log('submit')
	let tup = this.props.tup
	if(tup.cft_identifier.includes('num-input')){
	    this.post_vote('add_number')
	}
	this.setState({mode:'read'})
    }
    post_vote(vt:string){
	let tup = this.props.tup
	let d : {[k:string]:string} = {
	    acf_key: tup.key,
	    aspect:"huidig",
	    level2: 'NOT_LEVEL2',
	    post_id: this.props.post_id.toFixed(),
	    value: this.state.inputvalue,
	    votetype: vt
	}
	WpApi.do_post('/hd-attr',d, (r) => {
	    console.log(r)
	})
    }
    click_show_votes(){
	console.log('click show votes')
	let lbl = this.props.tup.label
	var d :{[k:string]:any}= {
	    acfkey: this.props.tup.key,
	    with_history:true,

	    history_popup: {
		post_id: this.props.post_id,
		acf_key: this.props.tup.key,
		choices:this.props.tup.choices,
		aspect: 'huidig',
		label: 'Historie',
		level2: 'NOT_LEVEL2'
	    },

	    label:lbl,
	    top3: [],
	    cmd: "number-vote",
	    aspect: "huidig",
	    trigger_recalc: true,
	}

	if(this.props.tup.cft_identifier === "txt-input"){
	    d['cmd'] = "text-vote"
	}
	if(this.props.tup.cft_identifier === "comment-input"){
	    d['cmd'] = "comment-vote"
	    d['acf_key_step'] = this.props.tup.key
	}
	if(this.props.tup.cft_identifier.includes("option-input")){
	    d['cmd'] = 'choice-vote'
	    if(this.props.tup.select_key === undefined){
		d['acf_key_select'] = this.props.tup.key
	    }else{
		d['acf_key_select'] = this.props.tup.select_key
	    }
	    d['acf_key_step'] = this.props.tup.key
	}
	this.props.cb_parent(d,(r:any)=>{
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else{
		console.log(r)
		if(r.winner !== undefined ){
		    this.setState({value:r.winner})
		}
	    }
	})

    }
    render_comments(){
	let cfg = this.props.config
	if(cfg.show_comments === "nee"){return null}
	return (
    	    <div className="comments">
	<i>zet hier je opmerkingen </i>
	    </div>
	)
    }
    render(){
	let tup = this.props.tup
	let cfid = tup.cft_identifier
	let cfg = this.props.config
	let with_comments = cfg.show_comments !== "nee"
	let cls = with_comments ? " with-comments":""

	let uservote = this.get_user_vote()
	let winner = this.render_winner_value()
	
	return (
	    <div className={"rm-invoer-field"+cls}>
		<div className="label-and-default">{tup.label} {winner}</div>
		<div className="value-container">
		{uservote}
	        <span className="show-votes" onClick={this.click_show_votes}>[..]</span>
		</div>
		{this.render_comments()}

	    </div>
	)
    }
}

export default RmInvoerField
