

class Loco {

    public static nl : {[key:string]:string} = {
	test: 'Test'
    }
    static default_nl : {[key:string]:string} = {
	attr_ref_attr : 'Pref. vote',
	choice_attr :  'Chosen option',
	comment_attr : 'Chosen comment',
	advise_attr : 'Chosen advise',
	history : 'History / log',
	active_attr : 'Active',
	deleted_attr : 'Deleted',
	change_comment_and_save : 'change comment and save',
	write_your_comment : 'write your comment',
	or_write_your_comment : 'or write your comment',
	write_your_comment_here : 'write your comment here',
	add_your_number : 'add your own number',
	or_add_your_number : 'or add your own number',
	choose_preferred_comment : 'Choose preferred comment',
	change_advise_and_save : 'Change advise and save',
	write_your_advise: 'Write your advise',
	or_write_your_advise: 'Or write your advise',
	header_huidig: 'Header huidig',
	header_voorstel:  'Header voorstel',
	add_advise: 'Add advise',
	no_value_yet: '(no value yet)',
	add_quotation: 'Add a quotation',
	cancel_quotation: 'Cancel quotation'
    }
    

    static  tr(value:string) : string{
	if(value in Loco.nl){
	    return Loco.nl[value]
	}
	if(value in Loco.default_nl){
	    return  Loco.default_nl[value]
	}
	return value
    }
}

export default Loco
