import * as React from 'react'



interface MyProps {
    tup:any
}
type MyState = {

}

class RmGlobalField extends React.PureComponent<MyProps,MyState> {
    render_field(){
	let tup = this.props.tup
	let skip = ["num-input", "calculation","","sub-header","txt-input","comment-input"];
	if(skip.includes(tup.cft_identifier)){
	    return null
	}
	if(tup.classes.includes('postmeta')){
	    return null
	}


	if(tup.cft_identifier.startsWith( "calc-default")){
	    return null
	}

	if(tup.cft_identifier === "option-input"){
	    if(tup.default_value === undefined){
		return null
	    }
	    if(tup.default_value === ""){
		return null
	    }
	    if(tup.default_value === false){
		return null
	    }
	}
	return (
	    <div className="step">
		<div className="label">{tup.label}</div>
		<div className="value">{tup.prepend}{this.get_value(tup,tup.append)} </div>
		<div className="type"> [{tup.cft_identifier}]</div>
	    </div>
	)
    }
    get_precision(){
	let tup = this.props.tup
	for(var cl of tup.classes){
	    if(cl.includes("precision")){
		let x = cl.split('-')
		return Number(x[1])
	    }
	}
	return 0
    }

    get_value(ctup:any,append:string){
	if(ctup.cft_identifier.endsWith('option-input')){
	    if(ctup.default_value !== undefined){
		let d = ctup.default_value
		if(d in ctup.choices){
		    return ctup.choices[d]
		}
	    }
	}
	if(ctup.value === undefined){return }

	if(ctup.classes.includes('percentage')){
	    append = '%'
	}
	let precision = this.get_precision()

	let numval = ctup.value
    	if(append === '%'){
	    numval *= 100
	}

	let strval = Number(numval).toFixed(precision).replace('.',',')
	return strval + ' ' + append	    

    }

    render(){
	return (
	    <div> {this.render_field()} </div>
	)
    }
}

export default RmGlobalField
