import * as React from 'react'

import WpApi from './wpapi'
import './css/RmInvoer.css'

import RmInvoerSection from './RmInvoerSection'

interface MyProps {
    post_id:number,
    show_comments:string,
    config:{ [k: string]: string }
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    sections:any
    tech:any
    msg:string
}

class RmInvoer extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    msg: '',
	    sections: {},
	    tech:{}
	}
    }
    init(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	let d = {
	    view: 'rm_invoer',
	    post_id: this.props.post_id.toFixed()
	    
	}
	let path = '/calc'

	this.fetchPromise = WpApi.do_get(path, d,(r:any) => {
	    if(r.code === "rest_forbidden"){
		this.setState({msg: 'No access. '+r.message})
	    }else{
		this.setState({
		    sections: r.sections,
		    tech: r.tech_settings
		})
	    }
	})
    }
    componentDidMount(){
	console.log('did mount')
	this.init()
    }
    show_row(row:any){
	if(row.classes.includes('zonder-invoer')){
	    return false
	}
	return true
    }

    render_section(row:any,i:number){
	let rowkey = row.name
	return (
	    <RmInvoerSection config={this.props.config} post_id={this.props.post_id} key={rowkey} tup={row} cb_parent={this.props.cb_parent} />
	)
    }
    render_sections(){
	return Object.entries(this.state.sections).map(([k,row]:[string,any], i:number) => {
	    return this.show_row(row) ? this.render_section(row,i) : null
	})
    }

    render(){
	return (
	    <div className="rm-invoer">
		<h3>Invoer</h3>
		{this.render_sections()}
	    </div>
	)
    }
}

export default RmInvoer
