import * as React from 'react'
import WpApi from './wpapi'
import Loader from './Loader'
import AdviesAanvraag from './AdviesAanvraag'
import './css/AdviesAanvragen.css'
import './css/ListGrid.css'
import VoteGridModal from './modals/VoteGridModal'
import GridHeader from './GridHeader'

interface MyProps {
    bbgroup:any
}
type MyState = {
    loading:boolean,
    options: any,
    permissions:any,
    rows:Array<any>
}
interface cb_after_vote_event {
    (r:any): void;
}

class AdviesAanvragenList extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>    
    private vote_popup =  React.createRef<VoteGridModal>()
    private callback_after_vote_event :cb_after_vote_event = (x)=>{ console.log("dummy")}

    constructor(props: MyProps) {
        super(props)
	this.open_popup = this.open_popup.bind(this)
	this.after_vote_event = this.after_vote_event.bind(this)	
	
	this.state = {
	    loading:false,
	    options:{},
	    permissions:{},
	    rows:[]
	}
    }
    public open_popup(e:any, cb:(r:any)=>void){
	this.callback_after_vote_event = cb
	console.log(e)
	let votetypes = [
	    'choice-vote',
	    'comment-vote'
	]

	let history = [
	    'open-history'
	]
	if(votetypes.includes(e.cmd)){
	    this.vote_popup.current!.init(e)
	}else{
	    console.log(`ERROR unknown cmd ${e.cmd}`)
	    return
	}

    }
    click_col_header(e:any){
	let gh = new GridHeader()
	gh.click_col_header(e)
    }
    change_filter_input(e:any){
	let gh = new GridHeader()
	gh.change_filter_input(e)
    }

    after_vote_event(v:any){
	console.log(`got vote-event kind ${v.kind}`)
	console.log(v)
	let d :{[name:string]:string}= {
	    post_id: v.post_id,
	    votetype: v.kind,
	}
	if(v.votetype !== undefined){
	    d.votetype = v.votetype
	}
	if(v.acf_key !== undefined){
	    d.acf_key = v.acf_key
	}
	if(v.kind === "add_comment"){

	    d.aspect = v.aspect
	    d.value = v.txt
	    d.level2 = v.level2
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}
	else if(v.kind === "update_comment"){
	    d.attr_id =  v.attr.attr_id
	    d.value = v.comment
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}
	else if(v.kind === 'remove_vote'){
	    d.attr_id = v.attr_id
	}
	else if(v.kind === 'chosen_option'){
	    d.value = v.value
	    d.is_final_field = v.is_final_field
	    d.trigger_recalc = v.trigger_recalc
	    d.aspect = v.aspect
	    if(d.acf_key === ""){
		d.acf_key = v.acf_key_select
	    }
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	    d.level2 = v.level2
	    
	}
	else{
	    console.log('error unknown kind')
	    return
	}
	console.log(d)
	WpApi.do_post('/hd-attr',d, (r) => {
	    this.callback_after_vote_event(r)
	})
	
    }
    init(){
	this.setState({loading:true})
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}

	let d = {
	    group_id : this.props.bbgroup.group_id
	}

	this.fetchPromise = WpApi.do_get('/hd-adviesaanvragen', d,(r) => {
	    let perm = r.permissions
	    perm.bbgroup = this.props.bbgroup
	    this.setState({
		rows  : r.rows,
		options : r.options,
		permissions: perm,
		loading:false
	    })
	})
    }    
    componentDidMount(){
	console.log('did mount')
	this.init()
    }
    render_rows(){
	return this.state.rows.map((row, i) => {
	    return (
		<AdviesAanvraag
		key={i} row={row}
		    cb_parent={this.open_popup}
		options={this.state.options}
		permissions={this.state.permissions}

		/>
	    )
	})
	
    }    
    render_header(){
       let titles = {
           date : 'Datum',
           adres: 'Adres',
           level1: 'Maatregel-type',
           level2: 'Maatregel',
	   adviseur: 'Extern Adviseur',
           value: 'Status'
       }
	return (
	    <div className={" header"}>
		<div className="pre-icon-h"></div>
		{Object.entries(titles).map(([name,label]:[string,string],i:number) => {
		    return (
			<div key={i} className={name + " hdr-label"} onClick={this.click_col_header}>
			<div className="label"><div className="txt">{label}</div></div>
			<input name={name} type={"text"} onChange={this.change_filter_input} />
				</div>
		    )
		})}

	    </div>
	)
    }
    render(){
	return (
	    
	    <div className="hd-actions-react listgrid adviesaanvragen">
		{this.state.loading ? <Loader /> : null}

	         {this.render_header()}

		<div className="body">
		{this.render_rows()}
	    </div>
		<VoteGridModal ref={this.vote_popup}
	    cb_parent={this.after_vote_event} post_id="undef" />
	    
	    </div>
	)
    }
}

export default AdviesAanvragenList
