import {get_config} from './config';

/*
 * Note on using cft-fast-mode 
 *  this is a rest-route provided by WP must-use plugin cf_exclude_plugins
 */



class WpApi{
    public static nonce: string = ''
    
    public static do_fetch(method:string,path:string, data:any,cb: (r:any) => void){
	console.log(`do http ${method}`)
	console.log(data)
	const conf = get_config()
	var hdrs : { [string: string]: string } = {}

	if(method === 'GET'){
	    hdrs['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
	}else{
	    hdrs['Content-Type'] = 'application/json'
	}

	var url = ''
	var fullpath = conf.wp_api_path + path
	if(path.startsWith("/wp-json")){
	    fullpath = path
	}else{
	    hdrs['X-CFT-FAST-MODE'] = 'true'
	}

	if(this.nonce){
	    // production-mode (embedded in WP)
	    // @ts-ignore
	    hdrs['X-WP-Nonce'] = this.nonce
	    url = fullpath
	}else{
	    // dev-mode ; running of nodejs-dev-server
	    url = conf.wp_api_host + fullpath
	}
	let bodydata = method === 'GET' ?   new URLSearchParams(data) : JSON.stringify(data)
	var requestOptions = {
	    method: method,
	    headers: hdrs,
	    body: bodydata
	}
	console.log(url)
	fetch(url,requestOptions)
	    .then(response => response.json())
	    .then(data => {
		cb(data)
	    })
    }
    public static do_post(path:string, data:any,cb: (r:any) => void){
	this.do_fetch('POST',path,data,cb);
    }
    public static do_patch(path:string, data:any,cb: (r:any) => void){
	this.do_fetch('PATCH',path,data,cb);
    }
    public static do_get(path:string, data:{[name:string]:string}, cb: (r:any) => void){
	const conf = get_config()

	var headers : { [string: string]: string } 	= {
	    'X-CFT-FAST-MODE': 'true'
	}
	let d = Object.entries(data).map(([key,val])=>{
	    return key + '=' + val
	})
	console.log(d)
	var url = ''
	var fullpath = conf.wp_api_path + path
	
	if(this.nonce){
	    // production-mode (embedded in WP)
	    headers['X-WP-Nonce'] = this.nonce
	    url = fullpath
	}else{	
	    // dev-mode ; running of nodejs-dev-server, CORS-mode
	    url = conf.wp_api_host + fullpath
	}

	url += '?' + d.join('&')
	console.log(url)
	return fetch(url,{ headers })
		.then(response => response.json())
		.then(data => {
		    cb(data)
		})
    }
}

export default WpApi
