import * as React from 'react'

import WpApi from '../wpapi'
import SummaryTable from './SummaryTable'
import TheForm from './TheForm'

interface MyProps {
    devmode:boolean
    hd:any
    level:string
}
type MyState = {
    view:string
    fields:any
    summary:any
    tab:string
    values:any
}
class DashInputFields extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
    constructor(props: MyProps) {
        super(props)
	this.field_change = this.field_change.bind(this)
	this.submit_form = this.submit_form.bind(this)
	
	this.state = {
	    view:'',
	    tab:'gasverbruik',
	    summary:{},
	    values:{},
	    fields:[]
	}
    }
    field_change(k:string,v:any){
	console.log(`field change ${k} = ${v}`)
	const values = { ...this.state.values, [k]:v }
	this.setState(() => ({ values }))

    }
    submit_field(f:any,v:any){
	let k = f.name
	let vt = ''
	let cft = f.cft_identifier

	if(cft.includes('num-input')){
	    vt = 'add_number'
	}
	else if(cft.includes('option-input')){
	    vt = 'choice'
	}
	
	let d = {
	    acf_key: f.key,
	    aspect:'huidig',
	    level2: 'NOT_LEVEL2',
	    post_id: this.props.hd.post_id,
	    value: v,
	    votetype: vt
	}
	console.log(d)
	WpApi.do_post('/hd-attr',d, (r:any) => {
	    console.log(r)
	})
    }
    submit_form(){
	console.log('submit form')
	let fields = this.state.fields[this.state.tab].fields
	console.log(fields)
	fields.forEach((f:any) => {
	    let k = f.name

	    if(f.type === "group"){
		f.fields.forEach((sf:any) => {
		    let k = sf.name
		    let v = this.state.values[k]
		    if(v !== undefined){
			console.log(sf)
			this.submit_field(sf,v)
		    }
		})
	    }else{
		let v = this.state.values[k]
		if(v !== undefined){
		    console.log(f)
		    this.submit_field(f,v)
		}
	    }
	    
	})
    }
    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	let path = '/hd-dash'
	let d : {[k:string]:any} = {
	    mode: 'getfields',
	    post_id: this.props.hd.post_id,
	    level: this.props.level
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    this.setState({ fields: r.fields })
	})
    }

    render_main(){
	let g = this.state.fields[this.state.tab]
	if(g === undefined){return null}

	return (
	    <TheForm
	    key={this.state.tab}
		fields={g.fields}
		cb_submit={this.submit_form}
		cb_field_change={this.field_change}
		mode={"dash-"+this.props.level}
	    />
	)
    }
    
    render_tab_nav(){
	let fields = Object.entries(this.state.fields).filter(([k,tup]:[string,any],i:number) => {
	    return tup.label !== undefined
	    
	})
	    
	return (
	    <div className="details-nav top-tab-nav">
		<div className="main-container">
		    <div className="layout-nav-items">
			{fields.map(([k,tup]:[string,any],i:number) => {
			    let cl = "nav-item details-nav-item"
			    if(k === this.state.tab){
				cl += " active"
			    }
			    if(tup.label === undefined){return null}
			    let idx=i+1
			    return (<div onClick={(e)=>{this.setState({tab:k})}} className={cl} key={i}> {idx}. {tup.label}</div>)
			})}
		    </div>
		</div>
	    </div>    
	)
    }
    render(){
	return (
	    <div>
		{this.render_tab_nav()}
		{this.render_main()}
	    </div>
	    
	)

    }
    
}
export default DashInputFields
