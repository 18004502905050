import * as React from 'react';

import WpApi from './wpapi'
import StepAspect from "./StepAspect"
import FilteredChoices from './filtered_choices'
import Expander_helper from './Expander_helper'

interface MyProps {
    post_id: string,
    tup: any,
    bptup?:any,  // buildingpart : when called by Level2Buildingpart
    options?:any,
    view?:string,
    l2cluster:any,
    permission:any,
    show_voorstel?:string,
    show_comments?:string,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}


type MyState = {
    supplier:string,
    supplier_choices: any,
    is_loading:boolean
};

interface cb_after_vote {
    (r:any): void;
}

class HdaStep extends React.PureComponent<MyProps,MyState> {
    
    private callback_after_vote :cb_after_vote = (x)=>{ console.log("dummy")}



    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.handle_info_click = this.handle_info_click.bind(this)
	this.handle_select_supplier = this.handle_select_supplier.bind(this)

	this.state = {
	    supplier:'',
	    is_loading:false,
	    supplier_choices:{}
	}
    }
    get_step_perm(name:string){
	let perm = this.props.permission.action
	let zwperm = this.props.permission.zwadvies
	if(name === "zw-advies"){
	    return {
		huidig: zwperm.huidig,
		voorstel: zwperm.voorstel,
		debug: 'zwadvies-step'
	    }
	}else{
	    return {
		huidig: perm.huidig,
		voorstel: perm.voorstel,
		debug: 'default step'
	    }
	}
    }

    componentDidMount(){

	if(this.with_adviseur_dropdown()){
	    if(this.props.bptup !== undefined && this.props.bptup.slug !== undefined){
		if(this.props.bptup.adviseur !== undefined){
		    let zw = this.props.bptup.adviseur
		    this.setState({
			supplier: zw.huidig.row.value
		    })
		}
	    }else{
		// level-1
		let atup = this.props.tup
		if(atup.adviseur !== undefined){
		    this.setState({
			supplier: atup.adviseur.value
		    })
		}
	    }
	}
    }
    
    /*
     *  Post een HD-attr voor gekozen leverancier bij de Uitvoeringstatus van een Level-2 cluster
     */
    handle_select_supplier(e:any){
	console.log('selected supplier')
	let slug = e.target.value
	console.log(`changed dropdown to: ${slug}`)
	let bp = this.props.bptup === undefined ? '' : this.props.bptup.slug
	let d = {
	    post_id : this.props.post_id,
	    votetype: 'choice',
	    value: slug,
	    level2:this.props.l2cluster.name,
	    bouwdeel: bp,
	    aspect: 'huidig',
	    acf_key: this.props.tup.adviseur.key,
	    is_final_field: "false",
	    trigger_recalc: "false",
	}
	console.log('set is loading true')
	this.setState({ is_loading:true})
	WpApi.do_post('/hd-attr',d, (r:any) => {
	    this.setState({
		is_loading:false,
		supplier:r.winner
	    })
	    this.props.cb_parent({'cmd':'update-adviseur','value':r.winner},()=>{})
	})
    }
    
    // send signal to parent ; to open the vote-grid
    // pass-on the signal from StepAspect
    handle_cb(e:any,cb:(r:any)=>void){
	this.callback_after_vote = cb;
	console.log("handle-cb")
	/*
	let label_txt = this.props.tup.label
	let slab = this.props.tup.section_label
	e.label = label_txt + ' ' + slab
	*/
	this.props.cb_parent(e,(r:any)=>{
	    console.log("received:")
	    console.log(r)
	    // reverse signal to StepAspect
	    this.callback_after_vote(r)
	})
    }
    public handle_info_click(){
	this.props.cb_parent({
	    cmd: 'open-info',
	    txt: this.props.tup.instructions
	},()=>{})
    }
    public render_instr(){
	if(this.props.tup.instructions){
	    return (<span className="cf-icon-btn"
		    data-balloon="Toon toelichting" data-balloon-pos="up"
		    onClick={this.handle_info_click}>
		<i className="bb-icon-rl bb-icon-info"/>
		</span>
		)
	}
    }
    with_adviseur_dropdown(){
	if(this.props.tup.classes.includes('with-adviseur')){
	    if(this.props.tup.adviseur === undefined){
		return false
	    }
	    if(this.props.tup.adviseur.key === ""){
		return false
	    }
	    return true
	}
    }
    get_adviseur_label(slug:string){
	let choices = this.props.options.adviseur.buttons_huidig.list
	let res = choices.filter((x:any) =>  x.name === slug)
	if(res.length === 1){
	    return res[0].label
	}
	return slug
    }
    render_adviseur_dropdown(){
	let supplier = this.state.supplier
	let choices1 = this.props.options.adviseur.buttons_huidig.list
	let prefix = "Optioneel: aan welke extern adviseur (mag leeg blijven)"
	let with_dropdown = supplier === null || supplier === ""
	
	if(this.props.tup.name  === "zw-advies"){
	    with_dropdown = false
	    prefix = "Toegewezen adviseur"
	}
	if(Object.entries(choices1).length ===0 ){
	    return null
	}
	let block_classes = this.props.tup.adviseur.classes
	let choices = choices1.filter((x:any) => {
	    for(var c of x.classes){
		if(block_classes.includes(c)){
		    return true
		}
	    }
	    return false
	})

	if(this.props.permission.bbgroup !== undefined){
	    let bbg = this.props.permission.bbgroup.gmember_level
	    if( bbg === 'admin' || bbg === 'mod'){
		with_dropdown = true
	    }
	}

	// DEBUG
	// with_dropdown = true
	if(!with_dropdown){
	    if(supplier === null || supplier === ""){
		return null
	    }
	    let adv_label = this.get_adviseur_label(supplier)
	    return (
		<div className="aux-option">
		    <div className="label">{prefix}</div>
		    {adv_label}
		</div>
	    )
	}
	return (
	    <div className="aux-option">
		<div className="label">{prefix}</div>

		<select style={{height:'unset'}} onChange={this.handle_select_supplier} value={supplier}>
		<option value="">-kies-</option>
		{choices.map((x:any,i:number) => {
		    return (<option key={i} value={x.name}>{x.label}</option>)

		})}
	    </select>
		</div>

	)
    }
    render_supplier_dropdown(){
	let choices = this.state.supplier_choices
	let prefix = "Door welke leverancier:"
	if(Object.entries(choices).length ===0 ){
	    return null
	}
	let supplier = this.state.supplier
	if(supplier){
	    let value = choices[supplier]
	    return (
		<div className="aux-option">
		    <div className="label">{prefix}</div>
		    {value}
		</div>
	    )
	}
	return (
	    <div className="aux-option">
		<div className="label">{prefix}</div>
		<select style={{height:'unset'}} onChange={this.handle_select_supplier}>
		<option value="">-kies-</option>
		{Object.entries(choices).map(([cname,clabel]:[string,any],i:number) => {
		    return (<option key={i} value={cname}>{clabel}</option>)
		})}
	    </select>
		</div>
	)
    }
    is_expanded(){

	if(this.props.options.config !== undefined){
	    let conf = this.props.options.config
	    if(conf.level2 === null){
		return conf.block === this.props.tup.key
	    }
	    let l2 = this.props.l2cluster.name
	    if( conf.level2 === l2){
		return conf.block === this.props.tup.key
	    }
	}

	return false
    }


    public render(){
	let label_txt = this.props.tup.label
	let label_txt2 = this.props.tup.label
	let parent_lab = this.props.tup.section_label

	if(this.props.l2cluster && this.props.l2cluster.label){
	    parent_lab = this.props.l2cluster.label
	}
	    

	let m = /^([^{]+){parent}/.exec(this.props.tup.label)


	if(m){
	    let lab1 = m[1].trim()
	    label_txt = (<span>{lab1}: <i>{parent_lab}</i></span>)
	    label_txt2 = (<span>{lab1} <i>{parent_lab}</i></span>)
	}
	let classes = this.is_expanded() ? "highlight"  : "collapsed"


	if(this.props.tup.label === ''){
	    return this.render_body("block",label_txt2)
	}else{
	    let exp = new Expander_helper();
	    let force_expanded = false
	    if(this.props.options !== undefined && this.props.options.context !== undefined){
		force_expanded = this.props.options.context === "adviesaanvragen"
	    }

	    return force_expanded ? (
		<div className="step without-expand" acf-key={this.props.tup.key}>
		    <div className="header" >

		        <div className="label">{label_txt} </div>
			{this.render_instr()}
		    </div>
		    {this.render_body("block",label_txt2)}
		</div>
	    ) : (
		<div className={"step with-expand "+classes} acf-key={this.props.tup.key}>
		    <div className="header"  onClick={exp.toggle_expand}>
		        <div className="pre-icon"></div>
		        <div className="label">{label_txt} </div>
			{this.render_instr()}
		    </div>
		    {this.render_body("body",label_txt2)}
		</div>
	    )
	    
	}
    }

    render_aspect(asp:string,label:string){
	if(asp === "voorstel" && this.props.tup.voorstel === undefined){
	    return null
	}
	
	if(asp === "voorstel" && this.props.show_voorstel === "nee"){
	    return null
	}
	let perm = this.get_step_perm(this.props.tup.name)
	let p = asp === 'huidig' ? perm.huidig : perm.voorstel
	let atup = this.props.tup[asp]
	if(atup === undefined){
	    return null
	}
	return (
	    <StepAspect
		post_id={this.props.post_id}
		label={label}
		view={this.props.view}
		l2cluster={this.props.l2cluster}
		stup={this.props.tup}
		show_comments={this.props.show_comments}
		bptup={this.props.bptup}
		permission={p}
		aspect={asp} tup={this.props.tup[asp]} acfkey={this.props.tup.key}
		cb_parent={this.handle_cb} />
	)
	
    }
    render_body(class_name:string,label_txt:string){
	// this.with_supplier_dropdown() ? this.render_supplier_dropdown() : null
	return (
	    <div className={class_name}>
		{this.with_adviseur_dropdown() ? this.render_adviseur_dropdown() : null}
		<div className="aspects two-columns">
		    {this.render_aspect('huidig',label_txt)}
		    {this.render_aspect('voorstel',label_txt)}
		</div>
	    </div>
	)
    }
}
export default HdaStep;
