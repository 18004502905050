import * as React from 'react'

import HdBlockList from './HdBlockList'

interface MyProps {
    post_id: string,
    user:any,
    filter:any,
    view:string,
    devmode:boolean,
    techindex:any,
    blocktype:string
}
type MyState = {
    tech:string
}

class RmSelectTech extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)

	this.set_techstate = this.set_techstate.bind(this)
	
	this.state = {
	    tech:''
	}
    }
    componentDidMount(){
	console.log('check techniek in query-params')
	let p = new URLSearchParams(window.location.search)
	let t = p.get('techniek')
	if(t !== null){
	    this.setState({tech:t})
	}else{
	    this.setState({tech: 't1'})
	}
	
	window.addEventListener("popstate", this.set_techstate)

    }
    set_techstate(e:any){
	let p = new URLSearchParams(window.location.search)
	let t = p.get('techniek')
	if(t !== null){
	    this.setState({tech:t})
	}	
    }
    click_nav(k:string){
	console.log(`click nav item ${k}`)
	this.setState({tech:k})
	const url = new URL(window.location.href)
	url.searchParams.set("techniek", k)
	window.history.pushState({tech:k}, "", url)

    }
    render_nav(){

	if(this.state.tech === undefined || this.state.tech === ""){
	    return (
		<div className="techniek-nav">
	    	    {Object.entries(this.props.techindex).map(([k,v]:[string,any],i:number)=>{
			return (
			    <div key={i} className="nav-item" onClick={()=>this.click_nav(k)}>Techniek:  {v}</div>
			)
		    })}
		</div>
	    )
	}else{
	    let sel = this.state.tech
	    let lab = this.props.techindex[sel]
	    if(this.props.view === "rm_summary"
		||this.props.view === "mini_summary"
	      ){
		return (
		    <div className="select-tech">{lab}</div>
		)
	    }
	    return (
		<div className="select-tech">
		    <select value={sel} onChange={(e)=>{this.click_nav(e.target.value)}}>
		    {Object.entries(this.props.techindex).map(([k,v]:[string,any],i:number)=>{
			return (
			    <option key={i} value={k}>Techniek:  {v}</option>
			)
		    })}

		</select>
		</div>
	    )
	}
    }
    render_app(){
	if(this.state.tech === undefined || this.state.tech === ""){
	    return null
	}
	let p = this.props
	return (
	    <HdBlockList
	    key={this.state.tech}
		post_id={p.post_id}
		user={p.user}
		blocktype={p.blocktype}
	    view={p.view}
	    devmode={p.devmode}
		filter={p.filter}
		tech={this.state.tech}
	    />
	)
    }
    render(){
	return (
	    <div>
	
		 {this.render_nav()}
		{this.render_app()}
	    </div>
	)
    }
}

export default RmSelectTech
