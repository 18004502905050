import * as React from 'react'
import Lightbox from './Lightbox'

interface MyProps {

}
type MyState = {
}
class SiteSupport extends React.PureComponent<MyProps,MyState> {

    render_content(){
	return (
	    <div>
		<h3 className="lightbox-title">Hulp nodig of vragen?</h3>
	    <form>
		<div className="hd-input-block simple-view text">
                    <div className="label-container">
			<label htmlFor="jouw-naam">Jouw naam</label>
                    </div>
                    <input id="jouw-naam" type="text" placeholder="" className="zw-medium-wide" required/>
		</div>

		<div className="hd-input-block simple-view email">
                    <div className="label-container">
			<label htmlFor="jouw-mail">Jouw e-mail</label>
                    </div>
                    <input id="jouw-e-mail" type="email" placeholder="" className="zw-medium-wide" required />
		</div>

		<div className="hd-input-block simple-view phone">
                    <div className="label-container">
			<label htmlFor="jouw-telefoon">Jouw telefoonnummer<span className="optional">(optioneel)</span></label>
                    </div>
                    <input id="jouw-telefoon" type="tel" placeholder="" className="zw-medium-wide"/>
		</div>

		<div className="hd-input-block simple-view textarea">
                    <div className="label-container">
			<label htmlFor="jouw-vraag">Jouw vraag/opmerking</label>
                    </div>
                    <textarea id="jouw-vraag"  placeholder="" className="zw-extra-wide" rows={12}></textarea>
		</div>
		</form>
		</div>
	)
    }
    render(){
	return (
	    <div className="layout-fixed-bottom">
		<div className="zw-content-area">
		<div className="zw-block inner-space-bottom">
		    <Lightbox btn_txt="Hulp nodig of vragen?" btn_img="adviseur" cls="content-align-right">
			{this.render_content()}
		    </Lightbox>
		</div>
		</div>
	    </div>
	)
    }
}

export default SiteSupport
