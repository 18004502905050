import * as React from 'react'
import WpApi from '../wpapi'

interface MyProps {
    fields: []
}
type MyState = {

}
class AvgHome extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)

	this.render_rows = this.render_rows.bind(this)
    }
    render_rows(footer:boolean){
	if(this.props.fields === undefined){
	    return null
	}

	let list = footer
	    ?
	    this.props.fields.filter((tup:any) => tup.classes.includes('footer'))
	    :
	    this.props.fields
	
	return list.map((tup:any,i:number) => {
	    return (
		<tr key={i}>
		<td> {tup.label} <span className="required">*</span></td>
		    <td> {tup.prepend} {tup.default_value} </td>
		</tr>
	    )
	})

    }
    render(){
	return (
	    <div className="zw-block">
		<h1>Ook van het gas af & overstappen op Zonnewarmte Ramplaankwartier?</h1>

	    <div className="zw-image-with-speech-bubble top-bubble mobile-reverse">
                <div className="speech-bubble rounded-shadow-container">
                    <h3>Voordelen voor een woning in Ramplaankwartier per jaar</h3>
		    <table>
			<tbody>
			    {this.render_rows(false)}
			</tbody>
			<tfoot>
			    {this.render_rows(true)}
			</tfoot>
		    </table>
		    <p className="required-explained"><span className="required">*</span>
			Dit zijn gemiddelden voor een woning in Ramplaankwartier. Dit kan in de praktijk fors afwijken van jouw woning.
		    </p>
		    
                </div>
		<img src={require("../banyan/img/startscherm-huisje-clean.png")}
	             className="big-space-below" />
		</div>
		</div>
	)
    }
}
export default AvgHome
