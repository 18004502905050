import React from 'react';

import HdaStep from './HdaStep'
import WpApi from './wpapi'
import Loader from './Loader'
import Loco from './Loco'
import './css/HdaList.css';
import VoteGridModal from './modals/VoteGridModal';
import HistoryModal from './modals/HistoryModal';
import InfoModal from './modals/InfoModal';

interface MyProps {
    params:any,
    post_id:string
}

type MyState = {
    loading:boolean,
    step_tup:any
}
interface cb_after_vote_event {
    (r:any): void;
}

class HdBlock extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
    private vote_popup =  React.createRef<VoteGridModal>()
    private history_popup =  React.createRef<HistoryModal>()
    private info_popup =  React.createRef<InfoModal>()
    private callback_after_vote_event :cb_after_vote_event = (x)=>{ console.log("dummy")}
											 

    constructor(props: MyProps) {
        super(props)
	this.open_popup = this.open_popup.bind(this)
	this.after_vote_event = this.after_vote_event.bind(this)
	
	this.state = {
	    loading:true,
	    step_tup:{}
	}
    }    
    componentDidMount(){

	console.log('did mount')
	this.init()
    }
    init(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	
	let d = {
	    post_id:this.props.post_id,
	    acfkey: this.props.params.acfkey,
	}

	this.fetchPromise = WpApi.do_get('/hd-actions', d,(r) => {
	    this.setState({step_tup  : r.step,
			   loading:false})
	})
	
    }



    public open_popup(e:any, cb:(r:any)=>void){
	console.log(`open-popup cmd: ${e.cmd}`)
	if(e.cmd === 'choice-vote'){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'advise-vote'){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'comment-vote'){
	    this.vote_popup.current!.init(e)
	}else if(e.cmd === 'open-history'){
	    this.history_popup.current!.init(e)
	}else if(e.cmd === 'open-info'){
	    this.info_popup.current!.init(e.txt)
	}
	// point callback to the calling Section
	this.callback_after_vote_event = cb;
	
    }
    /*
     *  user clicked in VoteGrid, send post to api
     */
    public after_vote_event(v:any){
	console.log(`got vote-event kind ${v.kind}`)
	console.log(v)
	var bkey = ''


	let d :{[name:string]:string}= {
	    post_id: this.props.post_id
	}
	if(v.tup !== undefined){
	    if(v.tup.select !== undefined){
		d.acf_key = v.tup.select.key
	    }
	}
	if(v.acf_key !== undefined){
	    d.acf_key = v.acf_key
	}
	if(v.votetype !== undefined){
	    d.votetype = v.votetype
	}
	if(v.aspect !== undefined){
	    d.aspect = v.aspect
	}
	if(v.bouwdeel !==undefined){
	    d.bouwdeel = v.bouwdeel
	}
	if(v.kind === 'choice_click'){
	    d.value = v.value
	    d.is_final_field = v.is_final_field
	}else if(v.kind === 'remove_vote'){
	    d.attr_id = v.attr_id
	    d.votetype = 'remove_vote'
	    d.is_final_field = v.is_final_field	    
	}else if(v.kind === 'add_comment'){
	    d.votetype = 'add_comment'
	    d.value = v.txt
	}else if(v.kind === 'add_advise'){
	    d.votetype = 'add_advise'
	    d.value = v.txt
	}else if(v.kind === 'update_advise'){
	    d.attr_id =  v.attr.attr_id
	    d.value = v.value
	}else if(v.kind === 'chosen_option'){
	    d.value = v.value
	    d.is_final_field = v.is_final_field
	    d.trigger_recalc = v.trigger_recalc
	    if(v.on_attr_id !== undefined){
		d.on_attr_id = v.on_attr_id
	    }
	}else if(v.kind === 'update_comment'){
	    d.attr_id =  v.attr.attr_id
	    d.value = v.comment

	}else{
	    console.log("unknown v.kind")
	    return
	}
	console.log(d)
	this.callback_after_vote_event({ hook: 'before_api_call'})

	WpApi.do_post('/hd-attr',d, (r) => {
	    this.callback_after_vote_event(r)
	})
	
    }

    render(){
	let params = this.props.params
	let tup = this.state.step_tup
	let pre_label = Loco.tr('your_digidome')
	let label = params.show_label ? (
	    <div className="hd-label">
		{pre_label}
		<a target="_blank" href={params.group.url}>{params.group.title}</a>
		</div>
	) : null

	let l2 = { name: 'NOT_LEVEL2' }
	return (
	    <div className="HdBlock hd-actions-react">
		{label}
		<div className="sections">
		    <div className="section">
			<div className="steps">
		{this.state.loading ? <Loader/> : null}
		{(Object.entries(tup).length === 0) ? null : (

		    <HdaStep
			l2cluster={l2}
			post_id={this.props.post_id}
			permission={tup.permission}
			tup={tup}
			cb_parent={this.open_popup}
		    />
		)}
			</div>
		    </div>
		</div>
		<VoteGridModal ref={this.vote_popup} cb_parent={this.after_vote_event}
			       post_id={this.props.post_id} />
		<HistoryModal ref={this.history_popup}  post_id={this.props.post_id} />
		<InfoModal ref={this.info_popup} />

	    </div>
	);
    }

}

export default HdBlock;
