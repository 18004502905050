import * as React from 'react'
import WpApi from '../wpapi'

import TheForm from './TheForm'


interface MyProps {
    cb_parent: ()=>void
    hd_inputs: {[k:string]:string}
    fields:any
}
type MyState = {
    values:any
    warning:string
}
class UserRegistration extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)
	this.kv_field_change =  this.kv_field_change.bind(this)
	this.form_submit = this.form_submit.bind(this)
	this.state = {
	    warning:'',
	    values: {}
	}
    }    
    input_change(e:any,field:string){
	let val = e.target.value
	console.log(` ${field} : ${val}`)
	
	const values = { ...this.state.values, [field]:val }
	this.setState(() => ({ values }))
	
    }
    kv_field_change(k:string, val:string){
	const values = { ...this.state.values, [k]:val }
	this.setState(() => ({ values }))
	
    }

    form_submit(){
	console.log('do http post')
	let path = '/aanmeldflow'
	let d = {
	    ...this.state.values,
	    level: 'register',
	    hd_inputs: this.props.hd_inputs
	}
	WpApi.do_post(path, d,(r) => {
	    console.log(r)
	    if(r.status === "ok"){
		console.log('ok')
		//this.props.cb_parent()
		let txt = 'Gelukt, we gaan verder in je dashboard zie /hd-dash'
		this.setState({'warning': txt})
		window.location.href = '/hd-dash'
		
	    }else{
		let w = r.code
		if(w === 'password'){
		    w = 'Het wachtwoord is niet sterk genoeg'
		}
		else if(w === 'password'){
		    w = 'Dit is geen geldig email adres'
		}
		this.setState({'warning':w})
	    }
	})
    }

    render(){
	let header = this.props.fields.label
	return (
	    <div className="zw-block create-account zw-block-hd-elements-less-space">
		<h3>{header}</h3>
		<div>Hierdoor kun je	altijd terug naar jouw persoonlijke pagina
            met ingevulde gegevens van jouw woning.</div>
		<TheForm mode="register" fields={this.props.fields.fields}
	           cb_field_change={this.kv_field_change}
		    cb_submit={this.form_submit}

		/>

		<div> {this.state.warning} </div>
		
	    </div>
	)
    }
}

export default UserRegistration
