import * as React from 'react'
import WpApi from '../wpapi'
import DashInputFields from './DashInputFields'




interface MyProps {
    devmode:boolean
    mode:string
}
type MyState = {
    view:string
    warning:string
    hd:any
    user:any
    fields:any
}
class HdDash extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	
    constructor(props: MyProps) {
        super(props)

	this.full_init = this.full_init.bind(this)
	this.state = {
	    warning: '',
	    hd:{},
	    user:{},
	    view:'dash',
	    fields:{}
	}
    }
    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return
	}
	
	if(this.props.devmode){
	    return
	}
	let path = '/hd-dash'
	let d : {[k:string]:any} = {
	    'mode': 'init'
	}

	this.fetchPromise = WpApi.do_get(path, d,(r:any) => {
	    if(r.code === 'rest_forbidden'){
		this.setState({warning: r.message})
		this.full_init()// debug
	    }else{
		console.log(r)
		if(! r.hd){
		    this.setState({warning: 'no hd in response'})
		}else{
		    if(r.hd.status === 'ok'){
			this.setState({
			    hd:r.hd,
			    user:r.user
			})
		    }else{
			this.full_init()			
		    }
		}
	    }
	})	
    }    
    full_init(){
	console.log('full init')
	let path = '/hd-dash'
	let ls_values = localStorage.getItem('values')
	let ls_bag = localStorage.getItem('bag')
	if(ls_values === null || ls_bag === null){
	    this.setState({warning: 'full-init needs values + bag from localStorage'})
	}else{
	    let d : {[k:string]:any} = {
		mode: 'full-init',
		values: JSON.parse(ls_values),
		bag: JSON.parse(ls_bag)
	    }

	    WpApi.do_post(path, d,(r:any) => {
		this.setState({
		    hd:r.hd,
		    user:r.user
		})
		this.create_initial_attr(r.attr)
	    })
	}	
    }
    create_initial_attr(attr:any){
	if(typeof attr.log === 'object'){
	    attr.log.forEach((x:any)=> {
		if(x.votetype !== undefined){
		    let d = {
			acf_key: x.key,
			aspect:'huidig',
			level2: 'NOT_LEVEL2',
			post_id: attr.post_id,
			value: x.value,
			votetype: x.votetype
		    }
		    console.log(d)
		    WpApi.do_post('/hd-attr',d, (r:any) => {
			console.log(r)
		    })
		}
	    })
	}
    }
    render_topnav(){
	return (
	    <div className="dashboard-nav top-tab-nav">
		<div className="main-container">
		    <div className="layout-nav-items">
			<a href="#" className="nav-item active">Jouw dashboard</a>
			<a href="#" className="nav-item">Jouw stappenplan</a>
		    </div>
		</div>
	    </div>
	)
    }
    render_left_nav(){
	return (
	    <div className="content-area left sub-nav my-account-nav bg-gray-light zw-block-bg">
		<ul>
		    <li><a href="#">Home</a></li>
		    <li className="has-children collapsible">
			<a href="#" className="toggle">Alle stappen van het gas af</a>
			<ul className="submenu collapsible-content">
			    <li><a href="#">Zonnewarmte advies</a></li> 
			    <li><a href="#">Isolatie</a></li> 
			    <li><a href="#">Elektra</a></li> 
			    <li><a href="#">Opwekinstallaties</a></li> 
			    <li><a href="#">Aansluiting op Zonnewarmte</a></li> 
			</ul>
		    </li> 
		    <li><a href="#">Jouw offertes</a></li> 
		    <li><a href="#">Kenniscentrum & FAQ</a></li> 
		    <li><a href="#">Voordelen voor leden </a></li> 
		    <li><a href="#">Financiering & subsidies</a></li> 
		    <li><a href="#">Contact met Zonnewarmte</a></li> 
		    <li><a href="#">Help mee</a></li> 
		</ul>
	    </div>
	)
    }
    render_main(){
	if(this.state.warning !== ''){
	    return (<div> Warning: {this.state.warning} </div>)
	}
	return (
	    <div id="main" className="main-wide">
		<div className="layout-main layout-main-sub-nav-with-right">
		{this.render_left_nav()}
	    {this.render_main_center()}
	    {this.render_right()}
	    </div>
		</div>
	)
    }
    render_main_center(){
	let user = '?'
	let userdata = this.state.user.data
	if(userdata !== undefined){
	    user = userdata.display_name
	}
	return (
            <div className="content-area main">
                <div className="zw-block">
                <h1>Hallo {user},</h1>
                    
                    <div className="text-container regular-text-container small-space-below">
                        <ul>
                            <li>Dit is jouw persoonlijke overzicht en actieplan voor jouw overstap naar Zonnewarmte.</li>
                            <li>Je kunt sommige stappen pas uitvoeren nadat je de voorgaande stap(pen) hebt afgerond. </li>
                            <li>Sommige stappen zijn niet afhankelijk van eerdere stap(pen).</li>
                        </ul>
                    </div>
		    
                </div>
		
                <div className="zw-block zw-block-bg bg-blue-light small-space-below">
		    
                    <div className="text-container regular-text-container small-space-below">
                        <span className="next-step to-be-checked">Eerstvolgende stap</span>
                        <h3>Laat jouw aanvraag checken door de Zonnewarmte-adviseur!</h3>
                        <ul className="checklist">
                            <li>Hulp bij het correct beantwoorden van detailvragen over je woning en energieverbruik</li>
                            <li>Isolatie-advies voor jouw woning</li>
                            <li>Inzicht of je woning al klaar is voor het Zonnewarmte-net</li>
                            <li>Adviesrapport met praktische tips voor besparing en te nemen maatregelen</li>
                            <li>Direct antwoord op al jouw vragen</li>
                        </ul>
                    </div>

                    <div className="zw-inner-block zw-inner-block-bg bg-white zw-advisor-container small-space-below">
                        <div className="text-container smaller-text">
                            <h5>Eenmalige kosten € 50,-</h5>
                            <ul>
                                <li>Normaal € 350 maar met € 300 subsidie van de gemeente</li>
                                <li>Voor Haarlem Pashouders zelfs helemaal gratis</li>
                                <li>Bij aansluiting op het warmtenet krijg je de € 50 terug</li>
                            </ul>
                        </div>
                        <div className="person-container">
                            <div className="person-image">
                <img src={require("../banyan/img/adviseur.png")} className="round-image img-extra-small" />
                            </div>
                            <div className="titles-container">
                                <h5>Jan van Galen</h5>
                                <h6>Zonnewarmte adviseur</h6>
                            </div>
                        </div>
                    </div>

                    <a href="#" className="button button-next element-space-below">Maak afspraak met Zonnewarmte adviseur</a>

                    <div className="zw-inner-block extra-actions">
                        <a href="#" onClick={(e)=>{ this.setState({view:'input3'})}} >Zonnewarmte advies stap overslaan</a>
                        <span className="extra-text">Ons team checkt binnenkort de door jouw ingevulde gegevens</span>
                    </div>

                </div>
                
                <div className="zw-block small-space-below progress-steps">
                    <h3>Taken om nu op te pakken</h3>
                    <div className="openup-blocks progress-step-container">
                        <div className="collapsible progress-step done">
                            <h5 className="toggle progress-step-title">Check door ZW-adviseur</h5>
                            <div className="collapsible-content progress-step-content">
                                <p>Ik ben al zo enthousiast dat ik mee wil doen en Zonnewarmte Ramplaankwartier wil steunen met het tekenen van de deelname intentieverklaring.</p>
                            </div>
                        </div>
                    </div>
                    <div className="openup-blocks progress-step-container">
                        <div className="collapsible progress-step">
                            <h5 className="toggle progress-step-title">Woning voldoende isoleren</h5>
                            <div className="collapsible-content progress-step-content">
                                <p>Ik ben al zo enthousiast dat ik mee wil doen en Zonnewarmte Ramplaankwartier wil steunen met het tekenen van de deelname intentieverklaring.</p>
                            </div>
                        </div>
                    </div>
                    <div className="openup-blocks progress-step-container">
                        <div className="collapsible progress-step">
                            <h5 className="toggle progress-step-title">3-fase meterkast en elektrisch koken</h5>
                            <div className="collapsible-content progress-step-content">
                                <p>Ik ben al zo enthousiast dat ik mee wil doen en Zonnewarmte Ramplaankwartier wil steunen met het tekenen van de deelname intentieverklaring.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
	)
    }
    render_right(){
	return (
            <div className="content-area right mobile-bg-light-gray">
                <div className="zw-block zw-block-bg bg-white desktop-border-medium-light">
                    <h2>Benodigd budget</h2>
                    <table>
                        <tbody>
                            <tr>
                                <td className="title">Zonnewarmte advies</td>
                                <td className="amount">€ 50</td>
                            </tr>
                            
                            <tr>
                                <td>Overgaan op elektrisch koken</td>
                                <td>€ 480</td>
                            </tr>
                            
                            <tr>
                                <td>Opwekinstallaties</td>
                                <td>€ 1.250</td>
                            </tr>
                            
                            <tr>
                                <td>Leidingwerk, omvormer, etc.</td>
                                <td>€ 6.550</td>
                            </tr>  

                            <tr>
                                <td>Aansluiting op buurtwarmtenet</td>
                                <td>€ 2.250</td>
                            </tr>   

                        </tbody>

                        <tfoot>

                            <tr className="totals">
                                <td>Totaal</td>
                                <td>€ 10.580</td>
                            </tr>

                        </tfoot>
                        
                    </table>

                    <h3>Verwachte opbrengst p.j.</h3>
                
                    <table>

                        <tbody>

                            <tr>
                                <td className="title">Lorem ipsum dolor</td>
                                <td className="amount">€ 50</td>
                            </tr>
                            
                            <tr>
                                <td>Dolor sit amet</td>
                                <td>€ 112</td>
                            </tr>
                            
                            <tr>
                                <td>Loit dolor</td>
                                <td>€ 312</td>
                            </tr>
                            
                            <tr>
                                <td>Ipsum dolor sit</td>
                                <td>€ 509</td>
                            </tr>  

                            <tr>
                                <td>Solor sit amet</td>
                                <td>€ 245</td>
                            </tr>   

                        </tbody>

                        <tfoot>

                            <tr className="totals">
                                <td>Totaal</td>
                                <td>€ 1.297</td>
                            </tr>

                        </tfoot>
                        
                    </table>

                </div>
            </div>
	)
    }
    form2_submit(){
	
    }
    kv_field_change(){
    }
    render(){
	let back_dash = (
	    <div onClick={(e)=>{this.setState({view:'dash'})}}> <a href="#">Dashboard</a></div>
	)
	let back2 = (
	    <div onClick={(e)=>{this.setState({view:'input2'})}}> <a href="#">Vragen Level 1+2</a></div>
	)
	let back3 = (
	    <div onClick={(e)=>{this.setState({view:'input3'})}}> <a href="#">Vragen Level3</a></div>
	)
	if(this.state.view === 'dash'){
	    return (
		<div>
		    {back2}{back3}
		    {this.render_topnav()}
		    {this.render_main()}
		</div>
	    )
	}
	else if(this.state.view === 'input2'){
	    return (
		<div>

		    {back_dash} {back3}

		    <div className="zw-block">
		    <p> [Vragen level 1+2] </p>
		    <DashInputFields key={this.state.view}
		hd={this.state.hd}
		devmode={this.props.devmode} level="level2"/>
		    </div>
		
		</div>
	    )
	}
	else if(this.state.view === 'input3'){
	    return (
		<div>

		    {back_dash} {back2}
		    <div className="zw-block">
		    <p> [Vragen level 3] </p>	
		    <DashInputFields key={this.state.view}
				hd={this.state.hd}
		devmode={this.props.devmode} level="level3"/>
		    </div>
		</div>
	    )
	}
    }
}

export default HdDash

