import * as React from 'react'

import RmInvoerField from './RmInvoerField'
import Expander_helper from './Expander_helper'


interface MyProps {
    tup:any,
    config:{ [k: string]: string }
    post_id:number,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
}

class RmInvoerSection extends React.PureComponent<MyProps,MyState> {
    render_field(tup:any,i:number){
	return this.show_field(tup) ?  (
	    <RmInvoerField post_id={this.props.post_id} config={this.props.config}
	    cb_parent={this.props.cb_parent}  key={tup.slug} tup={tup}/>
	) : null
    }
    render_body(rows:Array<any>){
	return (
	    <div className="body">
		{rows.map((tup:any,i:number)=> this.render_field(tup,i))}
	    </div>
	)
    }
    show_field(row:any){
	return row.cft_identifier.includes("input")

    }
    render(){
	let row = this.props.tup
	let exp = new Expander_helper();
	
	return (
	    <div className="section with-expand collapsed">
		<div className="header"  onClick={exp.toggle_expand}>
		    <div className="pre-icon"></div>
		    <div className="label">{row.label}</div>
		</div>
		{this.render_body(row.steps)}
	    </div>
	)

    }
}

export default RmInvoerSection
