
interface MyProps {
    label:string;
    name:string;
    choices:{[k:string]:string};
    val:string
    cb: (k:string,v:string)=>void
}


export default function UIChoicesRadioGroup({label,name,choices,val,cb}:MyProps){
    function onchange(k:string,v:string){
	cb(k,v)
    }





    //return render_radiogroup(label,name,null,null)

	let opt_list = Object.entries(choices).map(([k,lab]:[string,any],i:number) => {
	    let rid = name + '-' + k
	    return (
		<div key={i} className="radio-item">
		    <input id={rid} type="radio"
			   checked={k === val}
			   onChange={(e:any)=>{onchange(name,k)}}
			   name={name} value={k} />
		    <label htmlFor={rid}> {lab} </label>
		</div>
	    )
	})
    return (
	<div key={name} className="ui-choices-container">
	    <div className="header">{label}</div>
	    {opt_list}
	</div>
    )
    



}
