import * as React from 'react'



import Loco from './Loco'
import QuotationStep from './QuotationStep'
import Expander_helper from './Expander_helper'
import WpApi from './wpapi'
import BouwdeelAdvise from './BouwdeelAdvise'

interface MyProps {
    postid:number,
    slug:string,
    label_prefix:string,
    options:any,
    expanded:boolean,
    permission:any,
    l1cluster:any,
    l2cluster:any,
    advises: Array<any>,
    calculated:any,
    bouwdeel:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    is_loading:boolean,
    advises: {[aid:string]:any}    
}

class AdvisesList extends React.PureComponent<MyProps,MyState> {


    constructor(props: MyProps) {
        super(props)
    	this.handle_advise_comment_click = this.handle_advise_comment_click.bind(this)
	this.handle_advise_click = this.handle_advise_click.bind(this)
	
	this.state = {
	    is_loading:false,
	    advises:{}
	}
	
    }
    componentDidMount(){
	if(this.props.advises !== undefined){
	    this.setState({'advises': this.props.advises})
	}
    }
    get_label(){
	return this.props.label_prefix + this.props.l2cluster.label
    }
    handle_advise_click(){
	console.log('adding advise')
	let compact = this.props.l1cluster.name === "opwek"
	let bd = this.props.bouwdeel.slug
	let l2 = this.props.l2cluster.name
	this.props.cb_parent({
	    cmd: 'advise-vote',
	    with_history:true,
	    history_popup: {
		post_id: this.props.postid,
		acf_key: this.props.l1cluster.key,
		choices: [],
		bouwdeel:bd,
		aspect: 'voorstel',
		label: 'Historie',
		level2: l2
	    },
	    acf_key: this.props.l1cluster.key,
	    compactview: compact,
	    label: this.get_label(),
	    slug:this.props.slug,
	    level2: l2,
	    bouwdeel: bd,
	    aspect: 'voorstel',
	    top3: Object.keys(this.state.advises)
	}, (r:any)=>{
	    // callback is received twice:
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else{
		// 2. the API-call is finished, with result
		console.log('handle-advise-click ; after voting')
		if(r.bd_advise){
		    this.setState({advises:r.bd_advise, is_loading:false})
		}
	    }
	})
    }
    handle_advise_comment_click(attr_id:string, cb:(r:any)=>void){
	console.log(`advise-comment-click ${attr_id}`)
	let adv :any = this.state.advises[attr_id]
	let bd = this.props.bouwdeel.slug
	let l2 = this.props.l2cluster.name

	this.props.cb_parent({
	    cmd: 'advise-comment-vote',
	    with_history:true,
	    history_popup: {
		post_id: this.props.postid,
		acf_key: this.props.l1cluster.key,
		attr_id:attr_id,
		votetype:'comment',
		choices: [],
		bouwdeel:bd,
		aspect: 'voorstel',
		label: 'Historie',
		level2: l2
	    },
	    attr_id: attr_id,
	    label: this.get_label(),
	    top3: adv.comments
	}, (r:any)=>{
	    // callback is received twice:
	    if(r.hook === 'before_api_call'){
		// 1. the popup is closed but API-call is still running
		this.setState({is_loading:true})
	    }else if(r.votetype === "add_comment"){
		console.log('handle add-comment')
		this.setState({is_loading:false})
		cb(r)
	    }else{
		console.log('finally handle callback')
		this.setState({is_loading:false})
		cb(r)
	    }
	})
    }
    render_advises(){
	let compact = this.props.l1cluster.name === "opwek"

	return Object.entries(this.state.advises).map(([attr_id, tup]: [string, any]) => (
	    <BouwdeelAdvise
		attr_id={attr_id}
		value={tup.value}
		compactview={compact}
		comments={tup.comments}
		comment_count={tup.comment_count}
		handle_adv_comment_click={this.handle_advise_comment_click}
		handle_advise_click={this.handle_advise_click}
		key={attr_id} />
	))
    }
    render_calculated(){
	if(this.props.calculated === null){ return }
	if(this.props.slug !== "specs"){  return  }
	let l2 = this.props.l2cluster.name
	let data = this.props.calculated[l2]
	let numval = Number(data.value).toFixed(2)

	if(data === undefined){
	    return (
		<div>Calculated: undef</div>
	    )
	}else{
	    return (
		<div>Berekende waarde voor {data.field.label}: {numval} {data.field.append}</div>
	    )
	}
    }
    render(){
	let exp = new Expander_helper()
	let label =  (
	    <div className="label">
		<span>{this.props.label_prefix}
		<i> {this.props.l2cluster.label}</i></span>
		</div>
	)
	let collapsed = this.props.expanded ? "highlight" : "collapsed"

	return (
	    <div className={"quotations with-expand "+collapsed}>

		<div className="header" onClick={exp.toggle_expand}>
		    <div className="pre-icon"></div>
		    {label}
		</div>
		<div className="body">
		{this.render_calculated()}
	    	    <div className="pot-measures advises">
			{this.render_advises()}
	    		<span className="cf-button" onClick={()=>this.handle_advise_click()}>{Loco.tr('add_advise')}</span>
		    </div>
		</div>
	    </div>
	)
    }
}
export default AdvisesList

