import * as React from 'react'
interface MyProps {
    options:any
    mode:string
    view:string
}
type MyState = {}

class Rm20Headers extends React.PureComponent<MyProps,MyState> {
    render_avo(){
	let sel = this.props.options.bouwlagensleutel;
	
	return (
	    <div className="header-row grid-7">
		{Object.entries(this.props.options.columnsets.bouwlagen_avo).map(([k,lab]:[string,any],i:number) => {
		    if(k === sel){
			return (<div key={i} className={"selected "+k}>{lab}</div>)
		    }else{
			return (<div key={i} className={k}>{lab}</div>)
		    }
		})}
	    </div>
	)

    }
    render_bouwlagen4(){
	return (
	    <div className="header-row grid-4">
		{Object.entries(this.props.options.columnsets.bouwlagen_4).map(([k,lab]:[string,any],i:number) => {
		    return (<div key={i} className={k}>{lab}</div>)
		})}
	    </div>
	)

    }
    render_columns(name:string){
	let options = this.props.options.columnsets[name]
	let count = Object.keys(options).length
	return (
	    <div className={"header-row grid-"+count}>
		{Object.entries(options).map(([k,lab]:[string,any],i:number) => {
		    return (<div key={i} className={k}>{lab}</div>)
		})}
	    </div>
	)
	
    }
    render_abc(){
	if(this.props.options.skip_cols.length > 0){
	    // only 1 item
	    let only = this.props.options.scenario.substr(0,2)
	    return (
		<div className="header-row grid-3 only-one">
		    {Object.entries(this.props.options.columnsets.scenarios_abc).map(([k,lab]:[string,any],i:number) => {
			if(k === only){
			    return (<div key={i}>{lab}</div>)
			}else{
			    return (<div key={i} className="disabled">{lab}</div>)
			}
		    })}
		</div>
	    )
	}
	return (
	    <div className="header-row grid-3">
		{Object.entries(this.props.options.columnsets.scenarios_abc).map(([k,lab]:[string,any],i:number) => {
		    return (<div key={i}>{lab}</div>)
		})}
	    </div>
	)
    }
    render_a1c2(){
	if(this.props.options.skip_cols.length > 0){
	    // skip some 

	    return (
		<div className="header-row grid-6">
		    {Object.entries(this.props.options.columnsets.scenarios_a1c2).map(([k,lab]:[string,any],i:number) => {
			if(this.props.options.skip_cols.includes(k)){
			    return (<div key={i} className="disabled">{lab}</div>)
			}
			return (<div key={i}>{lab}</div>)
		    })}
		</div>
	    )
	}else{
	    return (
		<div className="header-row grid-6">
		    {Object.entries(this.props.options.columnsets.scenarios_a1c2).map(([k,lab]:[string,any],i:number) => {
			return (<div key={i}>{lab}</div>)
		    })}
		</div>
	    )
	}
    }
    render(){
	if(this.props.mode === 'avo'){
	    return (
	    <div className="rm20-header">
		{this.render_avo()}
		</div>
	    )
	}
	else if(this.props.mode === 'bouwlagen4'){
	    return (
		<div className="rm20-header">
		{this.render_bouwlagen4()}
		</div>
	    )
	}
	else if(this.props.mode === 'si_huidig_beoogd'){
	    return (
		<div className="rm20-header">
		    {this.render_columns('columns_huidig_beoogd')}
		</div>
	    )
	}
	else if(this.props.mode === 'gas_voor_na_doel'){
	    return (
		<div className="rm20-header">
		    {this.render_columns('gasverbruik_voor_na_doel')}
		</div>
	    )		
	}
	return (
	    <div className="rm20-header">
		{this.render_abc()}
	    	{this.props.mode === 'a1c2' ? this.render_a1c2() : null}
	    </div>
	)
    }
}

export default Rm20Headers
