import * as React from 'react'


import WpApi from '../wpapi'
import Loader from '../Loader'


import '../css/AanmeldFlow.css'
import UserRegistration from './UserRegistration'
import AvgHome from './AvgHome'
import SummaryTable  from './SummaryTable'
import TheForm from './TheForm'
import Lightbox from './Lightbox'

import SiteSupport from './SiteSupport'

import '../banyan/css/style.scss'
/*
  level:
  QL1 : 1e vragenlijst
  RL1 : 1e kassabon
  QL2 : 2e vragenlijst  incl kassabon

 */

/*
  devmode (boolean) : indicates if we are in localhost deployment 
  mode:
  - start :  show only QL1   yields a window.location redirect to mode=dash
  - classic : classic start ; dont redirect after QL1
  - dash  :  the rest of the flow   ; pickup inititially via URL-search-params provided in mode=start

 */
interface MyProps {
    devmode:boolean
    mode:string
}
type MyState = {
    loading:boolean
    fields:any
    level:string   // Ql1 
    summary:any
    message:string
    warning:string
    //registration:string
    values: {[k:string]:string}
}

class AanmeldFlow extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	private lightbox_registration =  React.createRef<Lightbox>()
    private lightbox_warnings = React.createRef<Lightbox>()


    constructor(props: MyProps) {
        super(props)

	this.callback_after_register = this.callback_after_register.bind(this)
	this.kv_field_change = this.kv_field_change.bind(this)
	this.form1_submit = this.form1_submit.bind(this)
	this.form2_submit = this.form2_submit.bind(this)
						   
	this.state = {
	    loading:false,
	    level:"QL1",
	    summary:{},
	    message:'',
	    //registration:'',
	    warning:'',
	    values:{},
	    fields:[]
	}
    }
    componentDidMount(){
	let ls_values = localStorage.getItem('values')
	if(ls_values !== null){
	    this.setState({values: JSON.parse(ls_values)})
	}

	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	let path = '/aanmeldflow'
	let d : {[k:string]:any} = {
	    'init' : this.state.level
	}
	if(this.props.mode=="dash"){
	    // pickup URL params
	    let p = new URLSearchParams(window.location.search)
	    d['level'] = "dash-1"
	    d['postcode'] = p.get('postcode')
	    d['huisnummer'] = p.get('huisnummer')
	    d['huidig_aantal_bewoners'] = p.get("aantal_bewoners")
	    d['gasverbruik_schatting_l1'] = p.get("gasverbruik")
	    
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    this.setState({ fields: r.fields })
	    if(this.props.mode === "dash"){
		this.process_summary_data(r)
	    }
	})
    }
    
    // callback from TheForm
    kv_field_change(k:string,v:any){
	const values = { ...this.state.values, [k]:v }
	localStorage.setItem('values', JSON.stringify(values));
	this.setState(() => ({ values }), ()=>{
	    if(this.state.level === 'QL2'){	
		this.refresh_summary_data()
	    }
	})
	
    }
    render_form1(){
	if(!this.state.fields.level_1){
	    return null
	}
	return (
	    <div className="zw-block zw-block-bg bg-dark">
		<h2 className="bigger">Doe de check</h2>
		<div className="vragenlijst level-1">
		<TheForm mode="level1"
		    fields={this.state.fields.level_1.fields}
		    cb_field_change={this.kv_field_change}
		    cb_submit={this.form1_submit}
		/>
		{this.state.loading ? <Loader /> :null}
		</div>
	    </div>
		
	)
    }

    render_form2(){
	if(!this.state.fields.level_2){
	    return null
	}
	//onClick={()=>{this.setState({registration:'start'})}}
	return (
	    
	    <div className="zw-block">
		<h1>Meer vragen over jouw woning</h1>
		<TheForm mode="level2"
		    fields={this.state.fields.level_2.fields}
		    cb_field_change={this.kv_field_change}
		    cb_submit={this.form2_submit}
		/>


		
		<Lightbox  ref={this.lightbox_registration}>
	    	    <UserRegistration
			cb_parent={this.callback_after_register} hd_inputs={this.state.values}
			fields={this.state.fields.user_registration}
		    />
		</Lightbox>

	    </div>
		
	)
    }
    validate1(){
	let path = '/aanmeldflow'

	let d : {[k:string]:any} = {
	    values: this.state.values,
	    level: 'validate-1'
	}
	WpApi.do_post(path, d,(r) => {
	    console.log(r)
	    if(r.status === "ok"){
		console.log('ok')
		let path = this.props.devmode ?  '/hd-app.html?' : '/hd-app/?'
		let v = this.state.values
		let params = new URLSearchParams({
		    postcode: v.postcode,
		    huisnummer: v.huisnummer,
		    gasverbruik: v.gasverbruik_schatting_l1,
		    aantal_bewoners: v.huidig_aantal_bewoners
		})
		window.location.href = path + params.toString()

	    }else{
		this.setState({'warning':r.code})
		if(r.code !== ''){
		    this.lightbox_warnings.current?.open_me({})
		}
	    }
	})
    }
    refresh_summary_data(){
	let path = '/aanmeldflow'

	this.setState({loading:true})
	let d : {[k:string]:any} = {
	    values: this.state.values,
	    level: 1
	}
	if(this.state.level === 'QL2'){
	    d.level = 2
	    d.bag = {
		opp: this.state.summary.opp,
		adres: this.state.summary.adres,
		bouwlagen: this.state.summary.bouwlagen
	    }
	}
	
	WpApi.do_post(path, d,(r) => {
	    console.log(r)
	    if(r.status === "ok"){
		console.log('ok')
		this.setState({loading:false})
		this.process_summary_data(r)
	    }else{
		this.setState({'warning':r.code})
		if(r.code !== ''){
		    this.lightbox_warnings.current?.open_me({})
		}
	    }
	})
    }

    form2_submit(){
	console.log('form2-submit')
	this.lightbox_registration.current?.open_me(null)
    }
    
    form1_submit(){
	if(this.props.mode === "start"){
	    this.validate1()
	}else{
	    this.refresh_summary_data()
	}
    }

    /*
     *  1e kassabon
     *   data: response from html-call
     */
    process_summary_data(data:any){
	console.log('show L1')
	console.log(data)
	let adr = ''
	
	if(this.state.level === 'QL1'){
	    this.setState({
		level: 'RL1',
	    })
	    if(data.bag.result === undefined){
		return
	    }
	    let nid = data.bag.result.nummeraanduiding_id
		
	    adr = data.bag.result.straat + ' ' + data.bag.result.huisnummer
	    if(data.bag.result.huisletter){
		adr += ' ' + data.bag.result.huisletter
	    }
	    this.setState({
		summary: {
		    adres: adr,
		    bouwlagen: data.bag.bag3d.b3_bouwlagen,
		    opp: data.bag.extra.verblijfsobject.oppervlakte,
		    calc:  data.calc
		}
	    })
	    localStorage.setItem('bag',  JSON.stringify({
		nid: nid,
		adres: adr,
		bouwlagen: data.bag.bag3d.b3_bouwlagen,
		oppervlakte: data.bag.extra.verblijfsobject.oppervlakte
	    }))
	}else{
	    // update summary state 
	    const summary = { ...this.state.summary, ['calc']:data.calc }
	    
	    this.setState(() => ({ summary }))
	}

	
    }
    // screen area - right
    render_right(level:number){
	if(level === 1){
	    return (
    		<div className="content-area right">

			{this.render_form1()}

		    <div className="message">{this.state.message}</div>
		</div>
	    )
	}
	else if(level === 2){
	    return (
		<div className="kassabon">
		    <SummaryTable data={this.state.summary}/>
		{this.state.loading ? <Loader /> :null}
		</div>
	    )
	}
    }

    callback_after_register(){
	console.log('callback after reg')
	this.lightbox_registration.current?.close_me({})
	//window.location.href = '/hd-dash'
    }
    // screen area - overlay
    render_warning(){
	let code = this.state.warning
	if(code === ''){ return null}
	let txt = code
	let elm = (<div></div>)
	if(code === 'not-in-bag'){
	    elm = (<div> Dit adres konden we niet vinden </div>)
	}else if(code === 'existing-hd'){
	    elm = (
		<div>
                    <div className="zw-message message-error small-space-below">
                        <p className="zw-text">Er is al een Zonnewarmte account voor dit adres</p>
                    </div>

                    <div className="buttons-row full-width">
                        <a href="/wp-login.php?redirect_to=%2Fhd-dash" className="button button-next zw-open-snackbar">Inloggen op jouw account</a>
                        <a href="/wp-login.php" className="button button-open button-next smaller-text button-full-width zw-open-snackbar">Vraag toegang tot dit account</a>
                    </div>

                    <span className="zw-message message-small message-centered"><p>Mogelijk heeft jouw huisgenoot al een account aangemaakt</p></span>

                </div>

	    )
	}
	
	
	return (
	    <div className="layout">
		<div className="content-area">
		    <Lightbox ref={this.lightbox_warnings} warning={this.state.warning}>{elm}</Lightbox>
		</div>
	    </div>
	)

    }
    // screen area - center
    render_main(level:number){
	if(level === 1){
	    if(this.state.level === "QL1" && this.state.fields.gemiddelde_woning !== undefined){

		let avgfields = this.state.fields.gemiddelde_woning.fields
		return (
		    <div className="content-area main">

			    <AvgHome fields={avgfields}/>

		    </div>
		)
	    }
	}else if(level === 2){
	    return (
		<div className="content-area main">
		    {this.render_form2()}
		</div>
	    )
	}
    }
    // screen area - center + optionally right
    render_content(){
	if(this.state.level === 'QL1'){
	    return (
		<div className="layout-main layout-main-with-right">
		    {this.render_main(1)}
		    {this.render_right(1)}
		</div>
	    )
	}else if(this.state.level === 'QL2'){
	    return (
		<div className="layout-main layout-main-with-right child-mobile-bg">
		    {this.render_main(2)}
		    {this.render_right(2)}
		</div>
	    )
	}else if(this.state.level === 'RL1'){
	    // kassabon standalone midden op pagina
	    let adres = this.state.summary.adres
	    return (
		<div className="layout-main layout-narrow">
		    <div className="content-area-main">
		    <div className="zw-block zw-block-bg bg-white desktop-border-medium-light medium-space-below">
			<div className="confirmation-message positive">
		    <h1 className="smaller">{adres} is geschikt voor Zonnewarmte Ramplaankwartier!</h1>
			</div>

			<SummaryTable data={this.state.summary}/>
		    </div>
		    <div className="buttons-row full-width extra-big-space-below">
			<h5>Maak je keuze </h5>
			<a onClick={()=> { this.setState({'level': 'QL2'})}} className="button button-next button-complex" >Ik wil misschien wel meedoen</a>
			{this.nieuwsbrief()}
			</div>
		    </div>
		    </div>
	    )
	}
    }
    



    nieuwsbrief(){
	return (
	    <Lightbox btn_txt="Ik schrijf me in voor de nieuwsbrief">
		    <h3 className="lightbox-title">Ik schrijf me in voor de nieuwsbrief</h3>
		    <div className="hd-input-block simple-view text">
			<div className="label-container">
			    <label htmlFor="jouw-naam">Jouw naam</label>
			</div>
			<input id="jouw-naam" type="text" placeholder="" className="zw-medium-wide" />
		    </div>
		    <div className="hd-input-block simple-view email">
			<div className="label-container">
			    <label htmlFor="jouw-mail">Jouw e-mail</label>
			</div>
			<input id="jouw-e-mail" type="email" placeholder="" className="zw-medium-wide"  />
		    </div>
		    <div className="hd-input-block simple-view phone">
			<div className="label-container">
			    <label htmlFor="jouw-telefoon">Jouw telefoonnummer<span className="optional">(optioneel)</span></label>
			</div>
			<input id="jouw-telefoon" type="tel" placeholder="" className="zw-medium-wide" />
		    </div>
		    <div className="hd-input-block horizontal-view checkbox">
			<input id="nschrijven-nieuwsbrief" type="checkbox" name="nschrijven-nieuwsbrief" value="inschrijven-nieuwsbrief" />
			<div className="label-container">
			    <label htmlFor="inschrijven-nieuwsbrief">Ik ontvang circa 2 x per maand een e-mail met update over Zonnewarmte Ramplaankwartier </label>
			</div>                            
		    </div>
		    <button  className="button button-open button-next less-height smaller-text">Hou me op de hoogte</button>
		</Lightbox>
	)
    }
    render_debug_buttons(){
	if(!this.props.devmode){
	    return null
	}
	return (
	    <div className="debug">
		<div onClick={(e)=>{this.setState({level:'QL2'})}}> fast-forward naar L2 vragen</div>
		</div>
	)
    }
    render(){
	return (
	    <div className="zw-main-container">
		{this.render_debug_buttons()}
		{this.render_content()}
	    {this.render_warning()}

	    <SiteSupport />
	    </div>
	)
    }
}

export default AanmeldFlow

