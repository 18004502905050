import * as React from 'react';

import Loco from './Loco'
import HdaStep from './HdaStep';
import Level2Clusters from "./Level2Clusters"
import DerivedValueStep from "./DerivedValueStep"
import V1Inputs from "./V1Inputs"
import CollabNumberInput from "./CollabNumberInput"
import Rm20Headers from './Rm20Headers'

import SubSection from "./SubSection"
import WpApi from './wpapi'
import Expander_helper from './Expander_helper'

import RmStep from './RmStep'

interface MyProps {
    post_id: string,
    user:any,
    tup: any,
    options:any,
    show_voorstel?:string,
    show_comments?:string,
    permissions:any,
    view:string,
    devmode?:boolean,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}


type MyState = {
    quotations:Array<any>,
    is_loading:boolean,
    comment_count:number,
    comments: Array<string>,
    completed:boolean
};

interface cb_after_vote {
    (r:any): void;
}

class HdaSection extends React.PureComponent<MyProps,MyState> {

    private current_row_key = "-";
    private callback_after_vote :cb_after_vote = (x)=>{ console.log("dummy")}

    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.handle_info_click = this.handle_info_click.bind(this)
	this.handle_comment_click = this.handle_comment_click.bind(this)

	this.state = {
	    quotations:[],
	    is_loading:false,
	    comment_count:0,
	    comments:[],
	    completed:false
	}
    }


    // send signal to parent ; to open the vote-grid
    public handle_cb(e:any, cb:(r:any)=>void){
	this.callback_after_vote = cb;
	console.log("handle cb")
	if(e.cmd === "set-action-status"){
	    console.log(e)
	    this.setState({ completed : (e.section_status === "ready")})
       }else if(e.cmd === "set-quotation-status"){
           console.log(e)
           console.log(`quotation-status: ${e.value}`)
           let d = {
               post_id : this.props.post_id,
               votetype: 'choice-on-attr',
               value: e.value,
               acf_key: e.acf_key,
               aspect: 'huidig',
               is_final_field: "false",
               trigger_recalc: "false",
               on_attr_id: e.on_attr_id
           }
           console.log(d)
           
           WpApi.do_post('/hd-attr',d, (r:any) => {
               console.log(r)
               this.callback_after_vote(r)
           })
	    
	}else{
	    this.props.cb_parent(e,(r:any)=>{
		console.log("result after vote:")
		console.log(r)
		// reverse signal to Step
		this.setState({ completed : (r.section_status === "ready")})
		this.callback_after_vote(r)
	    })
	}
    }
    is_expanded(){
	if(this.props.options.config !== undefined){
	    let conf = this.props.options.config
	    if( conf.level1 !== null){
		return conf.level1 === this.props.tup.key
	    }
	    return conf.blocktype === "simple"
	}
    }
    handle_info_click(){
	this.props.cb_parent({
	    cmd: 'open-info',
	    txt: this.props.tup.instructions
	},()=>{})
    }
    handle_comment_click(){
	console.log('comments-clicked')
	let lbl = 'Opmerkingen bij: ' +  this.props.tup.label
	this.props.cb_parent({
	    cmd: 'comment-vote',
	    acf_key_step: this.props.tup.key,
	    aspect:'huidig',
	    label: lbl
	},(r:any)=>{
	    console.log('callback after comment-vote')
	    let newtxt = r.txt
	    console.log(` add comment ${newtxt}`)
	    if(r.new_state){
		this.setState({
		    comments: r.new_state.map((x:any)=>x.value),
		    is_loading:false
		})
	    }
	})
    }
    /* render instructions */
    public render_instr(){
	if(this.props.tup.instructions){
	    return (<div className="info-popup"><span className="cf-icon-btn"
		    data-balloon="Toon toelichting" data-balloon-pos="up"
		    onClick={this.handle_info_click}>
		<i className="bb-icon-rl bb-icon-info"/>
		</span></div>
		)
	}
    }
    show_step(tup:any){
	if(this.props.view === "hdquotations"){
	    return tup.name === "zw-advies"
	}
	else if(this.props.view === "buildingmeasures"){
	    return tup.name === "oordeel"
		|| tup.name === "zw-advies"
	}
	else if(this.props.view === "dashboard"){
	    return tup.name !== "expert_advies_proces"

	}
	return true
    }
    componentDidMount(){
	this.setState({
	    //quotations: this.props.tup.quotations,
	    completed: (this.props.tup.value === 'ready')
	})

	/*
	if('l1comments' in this.props.tup){
	    let txt_array = this.props.tup.l1comments.map((x:any)=>x.value)
		this.setState({
		    comments: txt_array,
		    comment_count: this.props.tup.l1comment_count
		})
	    }
	*/
    }
    /*
    show_l1_comments(){
	if(!( 'l1comments' in this.props.tup)){
	    return false
	}
	return ['actionlist'].includes(this.props.view)
    }
    render_l1_comments(){
	return this.show_l1_comments() ? (
    	    <div className="step remarks l1-remarks"  onClick={this.handle_comment_click} >
		<div className="text-with-btn">
		    <div className="text">
			{this.state.comments.map((txt:string,j:number)=> {
			    return (<div key={j} className="part" >{txt}</div>)
			})}
		    </div>
		    <span className="cf-count-btn"
			  data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>
		</div>
	    </div>
	) : null
    }
*/
    /*
     *  part: (1 or 2)
     *    part=1: show all rows except class 'bottom'
     *    part=2: show only rows with class 'buttom'
     */

    get_substeps(){
	let ret : {[name:string]:Array<any>} = {}
	for(var tup of this.props.tup.steps){
	    if(tup.parent_id !== undefined){
		let p = tup.parent_id
		if(! Object.keys(ret).includes(p)){
		    ret[p] = []
		}
		ret[p].push(tup)
	    }
	}
	return ret
    }
    render_l1_blocks(part:number){
	
	
	return this.props.tup.steps.map((tup:any, j:number) => {
	    if(! this.show_step(tup)){
		return null
	    }

	    if(tup.classes !== undefined && tup.classes.includes('bottom')){
		if(part == 1){ return null }
	    }else{
		if(part == 2){return null}
	    }

	    if( tup.cftype === 'hda-choice-stap'){
		// render level-1 steps
		let l2 = { name: 'NOT_LEVEL2' }
		if(tup.classes.includes('only-level2')){return null}
		if(tup.classes.includes('verborgen')){return null}

		return (
		    <HdaStep
		    		    show_voorstel={this.props.show_voorstel}
		    show_comments={this.props.show_comments}
		    view={this.props.view}
		    l2cluster={l2}
		    options={this.props.options}
		    cb_parent={this.handle_cb}
		    permission={this.props.permissions}
		    key={tup.hash} tup={tup} post_id={this.props.post_id} />
		)
	    }else if( tup.cftype === 'sub-section'){
		//return this.render_subsection(j,tup)
		return (<SubSection key={j} tup={tup} cb_parent={this.handle_cb} options={this.props.options}
			permission={this.props.tup.permission}
			
		    />)
	    }else if( tup.cftype === 'hda-derived-field'){
		return (<DerivedValueStep tup={tup} key={j}/>)
	    }else if( tup.cftype === 'v1-inputs-with-comment'){
		return (<V1Inputs
			tup={tup} key={j}
			parent_label=""
			cb_parent={this.handle_cb}
			/> )
	    }else if( tup.cftype === 'rm-step'){
		return (<RmStep
			options={this.props.options}
			view={this.props.view}
			tup={tup} key={j}
			    cb_parent={this.handle_cb}
			/>)
	    }else{
		return null
	    }
	})
    }
    render_rm20_headers(){
	let hdv20 = ['rm_tech','rm_basis','rm_summary','mini_summary','warmtebesparing']
	
	if(hdv20.includes(this.props.view)){
	    let cls = this.props.tup.classes
	    let mode = cls.includes('headers_abc') ? 'abc' : ''
	    if(cls.includes('headers_a1c2')){
		mode = 'a1c2'
	    }else if(cls.includes('headers_bouwlagen4')){
		mode = 'bouwlagen4'
	    }else if(cls.includes('headers-si')){
		mode = 'si_huidig_beoogd'
	    }else if(cls.includes('headers_gas_voor_na_doel')){
		mode = 'gas_voor_na_doel'
	    }else if(cls.includes('headers_avo')){
		mode = 'avo'
	    }
	    if(mode === ''){ return null }
	    return (
		<Rm20Headers key={99} options={this.props.options} mode={mode} view={this.props.view} />
	    )
	}
    }
    render_tech_steps_nested(subs:any){
	return this.props.tup.steps.map((tup:any, j:number) => {
	    if(! this.show_step(tup)){
		return null
	    }else if( tup.cftype === 'rm-step'){
		if(tup.parent_id !==""){
		    return null
		}
		return (<RmStep
			options={this.props.options}
			 view={this.props.view}
			tup={tup} key={j}
			substeps={subs[tup.name]}
			cb_parent={this.handle_cb}
		    />)
	    }
	    return null
	})
    }
    render_tech_steps(){
	let exp = new Expander_helper();
	let subs = this.get_substeps()
	let label_txt = this.props.tup.label
	
	let classes = this.is_expanded() ? "highlight"  : "collapsed"
	return (
	    <div className={"section l1-cluster with-expand cluster "+classes}  acf-key={this.props.tup.key}>
		<div className="header"  onClick={exp.toggle_expand}>
		    <div className="pre-icon"></div>
		    <div className="label">
			{label_txt}
			{this.state.completed ? ' - AFGEROND' : null}
			{this.render_instr()}
		    </div>
		</div>
		<div className="body">
		    {this.render_rm20_headers()}
		    
		    <div className="steps">
			{this.render_tech_steps_nested(subs)}
		    </div>
		</div>
	    </div>
	)
	
    }
    public render(){
	if(this.props.view === "rm_tech"){
	    return this.render_tech_steps()
	}
	
	if(this.props.tup.cftype === 'hda-section' ||
	    this.props.tup.cftype === 'rm-section'
	  ){

	    let exp = new Expander_helper();
	    
	    let label_txt = this.props.tup.label
	    if(this.props.view === "actionlist"){
		label_txt = this.props.tup.cft_identifier + '. ' + this.props.tup.label
	    }
	    let classes = this.is_expanded() ? "highlight"  : "collapsed"

	 
	    return (
		<div className={"section l1-cluster with-expand cluster "+classes}  acf-key={this.props.tup.key}>
		    <div className="header"  onClick={exp.toggle_expand}>
			<div className="pre-icon"></div>
			<div className="label">
			    {label_txt}
			    {this.state.completed ? ' - AFGEROND' : null}
			    {this.render_instr()}
			</div>
		    </div>
		    <div className="body">
		    {this.render_rm20_headers()}

			<div className="steps">
			    {this.render_l1_blocks(1)}
			</div>
		    {this.show_level2() ? (
			<Level2Clusters
			permissions={this.props.permissions}
			options={this.props.options}
			user={this.props.user}
			cb_parent={this.handle_cb}
			view={this.props.view}
			post_id={this.props.post_id} tup={this.props.tup}  />
		    ) : null }
		
			<div className="steps">
		            {this.render_l1_blocks(2)}
			</div>
			
  		</div>
		</div>
	    )
	}

    }
    show_level2(){
	return this.props.tup.cftype === 'hda-section'
    }
}
export default HdaSection;
