import * as React from 'react';
import ValueWithComments from "./ValueWithComments"
import Loco from "./Loco"

interface MyProps {
    cb_parent: (e:any, cb: (r: any) => void) => void,
    tup:any
}
type MyState = {
    value:string
}

class CollabOutput extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.render_instr = this.render_instr.bind(this)

	this.state = {
	    value:''
	}
    }
    public set_winner(v:string){
	console.log(`set winner ${v}`)
	this.setState({value:v})
    }
    public render_instr(){
	if(this.props.tup.instructions){
	    return (
		<span className="cf-icon-btn"
		data-balloon="Toon toelichting" data-balloon-pos="up"
		onClick={() => 	this.props.cb_parent({
		    cmd: 'open-info',
		    txt: this.props.tup.instructions
		    },()=>{})}>
		<i className="bb-icon-rl bb-icon-info"/>
		</span>
	    )
	}
    }
    componentDidMount(){

	if(this.props.tup.nice_value){
	    this.setState({value: this.props.tup.nice_value})
	}
	else if(this.props.tup.value){
	    this.setState({value: this.props.tup.value})
	}
    }
    

    render(){
	let tup = this.props.tup
	let pre = this.props.tup.prepend
	let app = this.props.tup.append
	return (
	    <div className="collab-output section-part aspect panel">
		<div className="header">
		    <div className="label"> {tup.label} {this.render_instr()}</div>
		</div>
		<div className="body">
		    <div className="value">{pre} { this.state.value } {app}</div>
		</div>
	    </div>
	)
    }
}

export default CollabOutput
