import * as React from 'react'

import WpApi from './wpapi'

import Actiestap from './Actiestap'
import HdaStep from './HdaStep'
import HdaSection from './HdaSection'
import RmStep from './RmStep'

interface MyProps {
    post_id:number,
    show_voorstel:string,
    view:string,
    show_comments:string,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    calc:any,
    actiestappen:any
}

class WarmtebesparingAnalyse extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	private fetch_calc_promise ?: Promise<void>

    constructor(props: MyProps) {
        super(props)

	this.state = {
	    actiestappen:null,
	    calc:null
	}
    }

    componentDidMount(){
	this.init_actions()
	this.init_calc()
    }
    init_actions(){
	let d = {
	    post_id: this.props.post_id.toFixed(),
	    view:'warmtebesparing'
	}
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}

	
    	this.fetchPromise = WpApi.do_get('/hd-actions', d,(r) => {
	    this.setState({actiestappen: r})
	})
    }
    init_calc(){
	let d = {
	    post_id: this.props.post_id.toFixed(),
	    view:'warmtebesparing'
	}
	if (this.fetch_calc_promise){
	    console.log(' calc promise already found')
	    return;
	}
	
    	this.fetch_calc_promise = WpApi.do_get('/calc', d,(r) => {
	    this.setState({calc: r})
	})
    }

    render_actiestap(tup:any){
	let pid = this.props.post_id.toFixed()
	let opt = this.state.actiestappen.options
	let perm = this.state.actiestappen.permissions
	let l2 = { name: 'NOT_LEVEL2'}

	return (
	    <HdaStep
	    view={this.props.view}
		options={opt}
		show_voorstel={this.props.show_voorstel}
		show_comments={this.props.show_comments}
		post_id={pid} tup={tup} l2cluster={l2} permission={perm}
		cb_parent={this.props.cb_parent}
	    />
	)
    }
    render_actiestap_section(tup:any){
	return (
	    <div className="actiestap-section">
	    {tup.steps.map((s:any) => this.render_actiestap(s))}
	    </div>
	)
    }

    render_actiestappen(){
	if(this.state === null){return null}
	if(this.state.actiestappen === null){return null}
	return (
	    <div className="actiestappen">
		{this.state.actiestappen.sections.map((tup:any) => this.render_actiestap_section(tup))}
	    </div>
	)
    }
    render_input_fieldsections(){
	if(this.state === null || this.state.calc === null){return null}
	return (
	    <div>
		{this.state.calc.sections.map((tup:any)=> this.render_input_fields(tup))}
	    </div>
	)
    }
    render_input_fields(s0:any){

	//let s0 = this.state.calc.sections[0]
	let opt = this.state.actiestappen.options
	let perm = this.state.actiestappen.permissions

	return (
	    <HdaSection
		post_id={this.props.post_id.toFixed()}
		tup={s0} options={this.state.calc.options}
		cb_parent={this.props.cb_parent}
		permissions={perm}
		view={"warmtebesparing"}
		user={{}}
	    />
	)
	/*
	return (
	    <div> 
		{s0.steps.map((tup:any) =>
		    <RmStep tup={tup}
			      options={this.state.calc.options}
			      cb_parent={this.props.cb_parent}
		    />
		    )}
	    </div>
	    )
	    */
    }
    render(){
	return (
	    <div className="warmtebesparing-analyse">
		<h3>Warmtebesparing analyse</h3>
		{this.render_actiestappen()}
	    {this.render_input_fieldsections()}
	    </div>
	)
    }
}

export default WarmtebesparingAnalyse
