import * as React from 'react';
import HdaStep from './HdaStep';
import QuotationsList from './QuotationsList'
import AdvisesList from  './AdvisesList'
import ImplementationList from './ImplementationList'
import Loco from './Loco'
import Expander_helper from './Expander_helper'



interface MyProps {
    options:any,
    compactview:boolean,
    post_id:string,
    view:string,
    level1cluster:any,
    cb_parent: (e: any, cb: (r: any) => void) => void,
    permissions:any,

    stup:any,
    tup:any
}
type MyState = {
    is_loading:boolean
}
class Level2BuildingPart extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    is_loading:false,

	}
    }
    
    get_bouwdeel(){
	return {
	    slug: this.props.tup.slug
	}
    }
    get_l2cluster(){
	return {
	    name: this.props.stup.name,
	    label: this.props.stup.label
	}
    }
    is_expanded(){
	let name = this.props.tup.slug
	if(this.props.options.config !== null){
	    let conf = this.props.options.config
	    if(conf.bd !== undefined){
		return conf.bd === name
	    }
	}
	return false
    }
    render_implementation(){
	if(this.props.options.config !== null){
	    let conf = this.props.options.config
	    if(conf.blocktype === "simple"){
		return
	    }
	}
	
	if(this.props.view === "buildingmeasures"){return null}
	if(this.props.view === "dashboard"){return null}
	if(!this.props.level1cluster.classes.includes('l2implement')){
	    return null
	}
	let opt = this.props.options.repeater_blocks
	opt.config = this.props.options.config

	let expand_this = opt.config.mtype === "implementation"

	return  <ImplementationList
		    expanded={expand_this}
		    context={"level2cluster"}
		    implementations={this.props.tup.tup.implementations}
		    l2cluster={this.get_l2cluster()}
		    l1cluster={this.props.level1cluster}
		    permission={this.props.permissions}
		    bouwdeel={this.get_bouwdeel()}
		    options={opt}
		    postid={Number(this.props.post_id)}
		    cb_parent={this.props.cb_parent}
		 />

    }
    render_quotations(){
	if(this.props.view === "dashboard"){return null}
	if(this.props.view === "buildingmeasures"){return null}
	if(!this.props.level1cluster.classes.includes('l2quotations')){
	    return null
	}
	if(this.props.stup.classes !== undefined){
	    if(this.props.stup.classes.includes("no-l2quotations")){
		return null
	    }
	}
	let opt = this.props.options.repeater_blocks
	opt.config = this.props.options.config

	let expand_this = opt.config.mtype === "quotation"
	return  <QuotationsList
		    expanded={expand_this}
		    quotations={this.props.tup.tup.quotations}
		    l2cluster={this.get_l2cluster()}
		    l1cluster={this.props.level1cluster}
		    permission={this.props.permissions}
		    bouwdeel={this.get_bouwdeel()}
		    options={opt}

		    postid={Number(this.props.post_id)}
		    cb_parent={this.props.cb_parent}
		 />

    }
    render_l2location(){
	if(!this.props.level1cluster.classes.includes('l2location')){
	    return null
	}
	return this.render_pot_measures("location","Locatie-advies voor: ")
    }
    render_l2specs(){
	if(!this.props.level1cluster.classes.includes('l2specs')){
	    return null
	}
	return this.render_pot_measures("specs","Geadviseerde specs voor: ")
    }
    render_l2_potmeasures(){
	if(!this.props.level1cluster.classes.includes('l2potmeasures')){
	    return null
	}
	return this.render_pot_measures("potmeasure","Geadviseerde potentiële maatregelen voor: ")
    }
    render_pot_measures(rowkey:string,label:string){
	let attr_type = 'advise_'+rowkey
	if(this.props.view === "hdquotations"){return null}

	let opt = this.props.options.repeater_blocks
	opt.config = this.props.options.config

	let expand_this = opt.config.mtype === "potmeasure"

	let data = this.props.tup.tup[attr_type]
	let calcdata = this.props.tup.tup.calculated

	return <AdvisesList
		   key={rowkey}
		   expanded={expand_this}
		   label_prefix={label}
		   l2cluster={this.get_l2cluster()}
		   l1cluster={this.props.level1cluster}
		   permission={this.props.permissions}
		   bouwdeel={this.get_bouwdeel()}
		   advises={data}
		   options={opt}
		   calculated={calcdata}
		   slug={rowkey}
		   postid={Number(this.props.post_id)}
		   cb_parent={this.props.cb_parent}
	       />
    }

    // l2tup : item from stup.fields
    render_l2_block(l2tup:any,j:number,l2c:any){
	if(this.props.view === "hdquotations"){return null}
	if(this.props.view === "buildingmeasures" && l2tup.name !== "oordeel"){
	    return null
	}
	if(this.props.stup.classes !== undefined){
	    let check = "no-"+l2tup.name
	    if(this.props.stup.classes.includes(check)){
		return null
	    }
	}
	let bd = this.props.tup
	let bdtup :  {[name:string]:any} = {
	    slug: this.props.tup.slug,
	}
	if('tup' in bd){
	    let k = l2tup.key
	    if(k in bd.tup){
		console.log('yep')
		bdtup = bd.tup[k]
	    }
	}
	
	// bptup bevat data uit GET /buildingparts
	if(l2tup.classes === undefined){ return null  }

	if(l2tup.classes.includes('with-adviseur')){
	    let akey = this.get_key_adviseur()
	    if(akey){
		//let zwakey = this.get_props.options.zw_adviseur.key
		bdtup.adviseur = bd.tup[akey]
	    }
	}

	if(l2tup.classes.includes('verborgen')){
	    return null
	}

	return (
	    <HdaStep
	    options={this.props.options}
		l2cluster={l2c}
		bptup={bdtup}
	    cb_parent={this.props.cb_parent}
	    permission={this.props.permissions}
	    key={j} tup={l2tup} post_id={this.props.post_id} />
	)
    }
    get_key_adviseur(){
	for(var x of this.props.stup.fields){
	    if(x.name === 'adviseur'){
		return x.key
	    }
	}
    }
    /*
     *  part: (1 or 2)
     *    part=1: show all rows except "uitvoerings_status" 
     *    part=2: show only the row "uitvoerings_status" 
     */
    render_l2_blocks(part:number){
	let stup = this.props.stup
	let l2c = { label : stup.label, name: stup.name}
	return (
	    <div className="steps">
		{stup.fields.map((x:any, j:number) => {
		    if(part == 1){
			if(x.name === "uitvoering_status"){return null}
		    }
		    else if(part == 2){
			if(x.name !== "uitvoering_status"){return null}
		    }else{
			return null
		    }
		    return this.render_l2_block(x,j,l2c)
		})}
	    </div>
	)
    }

    get_choices(){
	let opt = this.props.options.repeater_blocks
	let buttons = opt.buildingparts.buttons
	let ret :{[name:string]:string}={}
	for(var x of buttons){
	    ret[x.name] = x.label
	}
	return ret
    }
    render(){
	let choices = this.get_choices()
	let label = choices[this.props.tup.slug]
	let highlight = this.is_expanded() ? "highlight" : ""
	let exp = new Expander_helper()
	if(this.props.compactview){
	    return (
		<div className="buildingpart">
		    {this.render_l2_blocks(1)}
		{this.render_l2_potmeasures()}
		    {this.render_quotations()}
		    {this.render_implementation()}
		{this.render_l2location()}
		{this.render_l2specs()}
		</div>
	    )
	}
	let hele_woning = this.props.tup.slug === "hele_woning"
	let hele_woning_cl = hele_woning ? " hele-woning" : ""
	return (
	    <div className={"buildingpart "+highlight + hele_woning_cl}>

		<div className="header">

		<div className="pre-icon"></div>
		{
		    hele_woning?null:(
			<div className="label">Bouwdeel: {label}</div>
		    )
		}
		</div>
		<div className="body">
		    {this.render_l2_blocks(1)}

	    		{this.render_l2_potmeasures()}
		{this.render_l2location()}
	    {this.render_l2specs()}

		    {this.render_quotations()}
		    {this.render_implementation()}
		</div>
	    </div>
		
	)

    }
}
export default Level2BuildingPart
