import * as React from 'react'

interface MyProps {
    mode:string
    fields: Array<any>
    cb_field_change:   (k:string,v: any) => void
    cb_submit: () => void
}
type MyState = {
    values:any
    warnings:any
}
class TheForm extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)

	this.on_submit_click = this.on_submit_click.bind(this)
	this.state = {
	    values:{},
	    warnings:{}
	}
    }

    componentDidMount(){
	let values : {[k:string]:any}= {}
	this.props.fields.forEach((f:any) => {
	    if(f.winner){
		values[f.name] = f.winner
	    }else{
		values[f.name] = ''
	    }
	    if(f.type === 'group'){
		f.fields.forEach((sf:any) => {
		    if(sf.winner){
			values[sf.name] = sf.winner
		    }else{
			values[sf.name] = ''
		    }
		})
	    }
	})
	this.setState({values:values})
	
    }    
    /*
     *  check if all values are present ; return True for all-good
     */
    validate() : boolean{
	if(["dash-level2", "dash-level3"].includes(this.props.mode)  ){
	    return true
	}
	let fields = this.props.fields.filter((x:any) => x.type !== 'message')
	let fieldnames = fields.map((x:any) => x.name)
	let allgood = true

	let warn :{[k:string]:any}= {}
	for(var x of fieldnames){
	    
	    if(this.state.values[x] === undefined ||
		this.state.values[x] === "" 
	      ){
		console.log(`oops ${x} is undef`)
		warn[x] = 'Vul dit nog in'
		allgood = false
	    }

	}
	this.setState({warnings:warn})
	return allgood
    }

    field_change(e:any,tup:any){
	let k = tup.name
	let v = e.target.value;
	let cft = tup.cft_identifier
	console.log(`field_change ${k} : ${v} ${cft}`)



	if(k === 'postcode'){
	    v = v.toUpperCase()
	    v = v.replaceAll(' ','')
	}

	if(cft !== undefined && cft.includes('num-input')){
	    v = v.replace(".",",")
	    if(isNaN(Number(v.replace(",",".")))){
		console.log('is-nan')
		return
	    }
	}
	this.props.cb_field_change(k,v.replace(",","."))
	const values = { ...this.state.values, [k]:v }
	//localStorage.setItem('values', JSON.stringify(values));
	this.setState(() => ({ values }))

	
    }
    on_submit_click(e:any){
	if(this.props.mode === "level2"
	   ||
	   this.validate()){
	    console.log('yup all good submit')
	    this.props.cb_submit()
	}
    }
    render_formfield(tup:any,i:number){
	let tuptype = tup.type
	if(tup.classes.includes('radio')){
	    tuptype = 'radio'
	}
	let typeclass : {[k:string]:string}= {
	    'text' : 'zw-input-text',
	    'radio' : 'zw-radio',
	    'select' : 'zw-dropdown'
	}
	if(this.props.mode === 'dash-level2'){
	    if(['postcode','huisnummer'].includes(tup.name)){
		return (
		    <div key={i} className={"hd-input-block simple-view "}>
			<div className={"label-container"}>
			<label> {tup.label}  : {tup.value}</label>
			</div>
			</div>
		)
	    }
	}
	let ts :string = tuptype
	let cls = tup.name + " " + typeclass[ts]
	if(tup.type === "message"){
	    if(tup.label.startsWith('introtekst')){
		return (
		    <p key={i} className="intro-text"> {tup.message}</p>
		)
	    }
	}
	let with_tooltip = ""
	let tooltip = null
	if(tup.instructions !== ""){
	    tooltip = (
		<div className="tooltip">
		    <span className="tooltip-text">{tup.instructions}</span>
		</div>
	    )
	    with_tooltip = " with-tooltip"
	}

	let warn = this.state.warnings[tup.name]
	let warn_elm = null
	if(warn !== undefined){
	    cls += " warning"
	    warn_elm = (
	        <span className="msg">{warn}</span>
	    )
	}
	return (
	    <div key={i} className={"hd-input-block simple-view "+cls}>
		<div className={"label-container"+with_tooltip}>
		    <label> {tup.label} </label>
		    {tooltip}
		</div>
		{this.render_input(tuptype,tup)}
	    {warn_elm}
	    {this.render_cft_instructions(tup)}
	    </div>
	)
    }
    render_input(tuptype:string,tup:any){
	if(tuptype === "text"){
	    return (
		<input type="text"
		       name={tup.name}
		       value={this.state.values[tup.name]}
		onChange={(e:any)=>{this.field_change(e,tup)}}
		    placeholder={tup.placeholder}
		    />
	    )
	}
	else if(tuptype === "radio") {
	    let val = this.state.values[tup.name]

	    let opt_list = Object.entries(tup.choices).map(([k,lab]:[string,any],i:number) => {
		let k1 = 'skip-option-'+k
		if(tup.classes !== undefined && tup.classes.includes(k1)){return null}
		let rid = tup.name + '-' + k
		return (
		    <div className="radio-item">
			<input id={rid} type="radio"
		    checked={k === val}
		          onChange={(e:any)=>{this.field_change(e,tup)}}
		          name={tup.name} value={k} />
			<label htmlFor={rid}> {lab} </label>
			</div>
		)
	    })
	    return (<div className="radio-items-container">{opt_list}</div>)
	}
	else if(tuptype === "select") {
	    let opt_list = Object.entries(tup.choices).map(([k,lab]:[string,any],i:number) => {
		let k1 = 'skip-option-'+k
		if(tup.classes !== undefined && tup.classes.includes(k1)){return null}
		return (
		    <option key={i} value={k}> {lab} </option>
		)
	    })
	    return (
		<select className="zw-narrow"
			name={tup.name}
			value={this.state.values[tup.name]}
		onChange={(e:any)=>{this.field_change(e,tup)}}		    
		    >
		    <option>Selecteer</option>
		    {opt_list}
		</select>
	    )
	}
	else if(tup.type === "password"){
	    return (
		<input type="password" name={tup.name} onChange={(e)=>this.field_change(e,tup)} />
	    )
	    
	}
	else if(tup.type === "checkbox"){
	    return (
		<input type="checkbox" onChange={(e)=>this.field_change(e,tup)} />
	    )

	    
	}
	else if(tup.type === "group"){
	    return (
		<div className="fields-inner-container horizontal-layout">
		{tup.fields.map((e:any) => (
		    <div className="field-inner-container">
			<div className="label">{e.label}</div>
			{this.render_input(e.type,e)}
		    </div>
		))}

		</div>
	    )
	}
    }
    // Explanation underneath
    render_cft_instructions(tup:any){
	let txt = tup.cft_instructions	
	if(txt === undefined || txt === ""){
	    return null
	}
	let header = ""
	if(txt.includes('||')){
	    [header,txt] = txt.split('||')
	}
	let cl = this.props.mode === 'level1' ? "bg-blue" : ""
	return (
	    <div className={"explanation "+cl}>
		<h5>{header}</h5>
		<p>{txt}</p>
	    </div>
	)
    }
    render_submit_btn(){
	let m = this.props.mode

	if(m === "level2"){
	    // double whammy
	    return (
		<div>
		    <div className="hd-input-block action-container medium-space-above" onClick={this.on_submit_click}>
			<div className="submit-knop" > 
			    <a className="button button-next lower" >Verder</a>
			</div>
                    </div>
                    <div className="hd-input-block action-container small-space-above">
			<div className="save-fields-container" onClick={this.on_submit_click}>
                            <h5>Later verder gaan?</h5>
                            <a href="#">Opslaan ingevulde gegevens</a>
			</div>
                    </div>
		</div>
	    )
	}

	else{
	    let elm = (
		<a className="button button-next" >Sla op </a>
	    )
	    if(m === "level1"){
		elm = (
		    <a className="button button-next" >Doe "van het gas af" check </a>
		)
	    }else if(m === "register"){
		elm = (
		    <a className="button button-open button-next smaller-text" >Maak mijn account voor Zonnewarmte aan</a>
		)
	    }
	    
	    
	    return (
		<div className="submit-knop" onClick={this.on_submit_click}>
                    {elm}
               </div>
	    )
	}
    }

    render(){
	return (
    	    <form>
		{this.props.fields.map((field:any,i:number)=>
		    this.render_formfield(field,i)
		)}
		{this.render_submit_btn()}
	    </form>

	)
    }
}

export default TheForm

