import Lightbox from './Lightbox'


export default function ExplainCalculation(){
    return (
	    <Lightbox btn_txt="Toon me de berekeningen" cls="explain-calc">



        <div className="zw-lightbox-header">
            <h2 className="lightbox-title-big">Hoe wordt jouw kostenplaatje berekend?</h2>
        </div>
        
        <div className="zw-lightbox-body zw-calculation-visualization-container zw-visualization-container">

            <div className="zw-col">

                <h4>Gasaansluiting</h4>

                <div className="calculation-blocks">
                    
                    <div className="calculation-block">
                        <h5>Gasverbruik</h5>
                        <p>Dit heb je zelf ingevuld</p>
                    </div>

                    <div className="calculation-block">
                        <h5>Vaste kosten voor gasaansluiting</h5>
                        <p>Ook wel vastrecht genoemd</p>
                    </div>

                    <div className="calculation-block">
                        <h5>Afschrijving CV</h5>
                        <p>Kosten voor vervanging na 15 jaar</p>
                    </div>

                    <div className="calculation-block">
                        <h5>Onderhoud CV</h5>
                        <p>Vast onderhoudscontract met installateur en/of incidenteel problemen oplossen</p>
                    </div>

                </div>

            </div>

            <div className="zw-col zw-col-with-visual inner-space-top-extra-big inner-space-not-mobile">

                <div className="zw-visual-container">
                    <div className="zw-arrow-container arrow-left">
			<img src={require("../banyan/img/icons/icon-calculation-arrow-left.png")} />
                    </div>
                    <div className="zw-icon-container">
			<img src={require("../banyan/img/icons/icon-calculation-sheet.png")} />
                    </div>
                    <div className="zw-arrow-container arrow-right">
			<img src={require("../banyan/img/icons/icon-calculation-arrow-right.png")} />
                    </div>
                </div>
            </div>
            <div className="zw-col">

                <h4>Energie uit Zonnewarmte</h4>

                <div className="calculation-blocks">
                    
                    <div className="calculation-block">
                        <h5>m2 van jouw woning </h5>
                        <p>De oppervlakte van je woning weten we uit openbare gegevens van het kadaster.</p>
                    </div>

                    <div className="calculation-block">
                        <h5>Aantal bewoners</h5>
                        <p>Dit heb je zelf ingevuld. Samen met m2 is dit voorlopig de basis voor deze berekening</p>
                    </div>

                    <div className="calculation-block">
                        <h5>Vaste kosten voor Zonnewarmte</h5>
                        <p>Net als bij gas, betaal je ook bij Zonnewarmte een vast bedrag voor de aansluiting.</p>
                    </div>

                    <div className="calculation-block">
                        <h5>Elektra-opbrengst PVT panelen</h5>
                        <p>Overschot opgewekte stroom</p>
                    </div>

                    <div className="calculation-block">
                        <h5>Elektrakosten warmtepomp</h5>
                        <p>Warmtepomp verbruikt ook elektra</p>
                    </div>

                    <div className="calculation-block">
                        <h5>Afschrijving PVT panelen en warmtepomp</h5>
                        <p>Kosten voor vervanging </p>
                    </div>

                    <div className="calculation-block">
                        <h5>Onderhoud PVT panelen en warmtepomp</h5>
                        <p>Vast onderhoudscontract met installateur en/of incidenteel problemen oplossen </p>
                    </div>

                </div>

            </div>

        </div>




	    </Lightbox>
    )
}
