import * as React from 'react'

import WpApi from './wpapi'

interface MyProps {
    post_id: string,
    
}
type MyState = {
    postcode:string
    huisnum:string
    msg:string
    woonopp:string
    bouwlagen:string
    adres:string
}

class HdBaseInput extends React.PureComponent<MyProps,MyState> {

    constructor(props: MyProps) {
        super(props)

	this.on_change_pc = this.on_change_pc.bind(this)
	this.on_change_huisnum = this.on_change_huisnum.bind(this)
	this.on_click_save = this.on_click_save.bind(this)
	this.state = {
	    msg:'',
	    postcode:'',
	    huisnum:'',
	    woonopp:'',
	    bouwlagen:'',
	    adres:''
	}	
    }
    on_change_pc(e:any){
	let v = e.target.value
	this.setState({postcode:v})
    }
    on_change_huisnum(e:any){
	let v = e.target.value
	this.setState({huisnum:v})
    }
    on_click_save(){
	console.log('save')
	let path = '/aanmeldflow'
	let d = {
	    post_id: this.props.post_id,
	    postcode: this.state.postcode,
	    huisnummer: this.state.huisnum,
	    level: 'base-init'
	}
	
	WpApi.do_post(path, d,(r:any) => {
	    console.log(r)
	    if(r.status === 'ok'){
		this.setState({
		    msg: 'ok',
		    woonopp: r.woonoppervlak,
		    bouwlagen: r.bouwlagen,
		    adres: r.adres,
		})
	    }else{
		this.setState({
		    msg: r.msg
		})
	    }
	})
    }
    render(){
	if(this.state.msg === 'ok'){
	    return (
		<div>
		    <h2>Gevonden in BAG:</h2>
		    <div>Adres: {this.state.adres} </div>
		    <div>Woonoppervlak: {this.state.woonopp} </div>
		    <div>bouwlagen: {this.state.bouwlagen} </div>
		    <hr/>
		    <p> herlaad de pagina om verder te gaan </p>
		    </div>
	    )
	}
	return (
	    <div> <h3>base-input </h3>
		Postcode: <input type="text" name="postcode" onChange={this.on_change_pc} />
	    Huisnum: <input type="text" name="huisnum" onChange={this.on_change_huisnum} />	    
	        <button onClick={this.on_click_save}>save</button>
		<div className="message">{this.state.msg}</div>
	    </div>
	)
    }
}

export default HdBaseInput
