import * as React from 'react';

import ReactModal from 'react-modal';



interface MyProps {

}


type MyState = {
    showModal:boolean,
    text:string
};

class InfoModal extends React.PureComponent<MyProps,MyState> {


    constructor(props: MyProps) {
        super(props)
    	this.handleCloseModal = this.handleCloseModal.bind(this)

	this.state = {
	    showModal:false,
	    text:''
	}
    }
    public handleCloseModal(){
	this.setState({ showModal: false });
    }
    public init(txt:string){
	this.setState({showModal:true, text:txt})
    }

    
    public render(){
	const customStyles = {
	    content: {
		top: '10%',
		left: '20%',
		right:'30%',
		bottom:'20%'
		
	    },
	};
	return (
	    <ReactModal
	            ariaHideApp={false}
	            contentLabel="my test modal"
	            style={customStyles}
	            isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={true}
		>
		<div className="popup-buttons">
                  <span className="cf-icon-btn" data-balloon="Sluit" data-balloon-pos="down" onClick={this.handleCloseModal}><i className="bb-icon-l bb-icon-times"/></span>
		</div>
		 <div dangerouslySetInnerHTML={{ __html: this.state.text }} />

	     </ReactModal>
	)
    }    
}

export default InfoModal;
