import * as React from 'react'

import WpApi from './wpapi'

import Actiestap from './Actiestap'
import HdaStep from './HdaStep'
import HdaSection from './HdaSection'
import RmStep from './RmStep'

interface MyProps {
    post_id:number,
    show_voorstel:string,
    show_comments:string,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}
type MyState = {
    calc:any,

}

class OpwekCap extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	private fetch_calc_promise ?: Promise<void>

    constructor(props: MyProps) {
        super(props)

	this.state = {
	    calc:null
	}
    }

    componentDidMount(){
	this.init_calc()
    }

    init_calc(){
	let d = {
	    post_id: this.props.post_id.toFixed(),
	    view:'opwek_cap'
	}
	if (this.fetch_calc_promise){
	    console.log(' calc promise already found')
	    return;
	}
	
    	this.fetch_calc_promise = WpApi.do_get('/calc', d,(r) => {
	    this.setState({calc: r})
	})
    }

    render_input_fieldsections(){
	if(this.state === null || this.state.calc === null){return null}
	return (
	    <div>
		{this.state.calc.sections.map((tup:any)=> this.render_input_fields(tup))}
	    </div>
	)
    }
    render_input_fields(s0:any){
	let opt = this.state.calc.options
	let perm = this.state.calc.permissions

	return (
	    <HdaSection
		post_id={this.props.post_id.toFixed()}
		tup={s0} options={this.state.calc.options}
		cb_parent={this.props.cb_parent}
		permissions={perm}
	    view={"opwek_cap"}
		user={{}}
	    />
	)
	/*
	return (
	    <div> 
		{s0.steps.map((tup:any) =>
		    <RmStep tup={tup}
			      options={this.state.calc.options}
			      cb_parent={this.props.cb_parent}
		    />
		    )}
	    </div>
	    )
	    */
    }
    render(){
	return (
	    <div className="warmtebesparing-analyse">
		<h3>PVT-installatie analyse</h3>
		{this.render_input_fieldsections()}
	    </div>
	)
    }
}

export default OpwekCap
